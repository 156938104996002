import React, { Component } from "react";
import {
  Grid,
  Row,
  Col
} from "react-bootstrap";

import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";
import HttpLinkServices from "../../services/HttpLinkServices";

const account_client = new ApolloClient({
  link: HttpLinkServices.account,
  cache: new InMemoryCache(),
});

const ADD_TODO = gql`
  mutation createOrUpdateProfile($id: Int!,  $title: String!){
    createOrUpdateProfile(id:$id, input:{title:$title}){
      id
    }
  }
`;

const GET_PROFILE = gql`
  query getProfile($profile_id: Int!){
    profile(id:$profile_id){
      id
      title
    }
  }
`;



class ProfileItem extends Component {

  profile_id;
  profile_edit;

  constructor() {
    super();
    this.state = {
      alertInfo: ""
    };

  }


  handleSubmit = (event) => {
    event.preventDefault();
    
    this.setState({
      alertInfo: "Salvo com sucesso!"
    });

    setTimeout(() => {
      this.setState({
        alertInfo: ""
      })
    },3000);
  }

  
  render() {

    
    if(this.props.match.params.id) {
      this.profile_id = this.props.match.params.id;
    } else {
      this.profile_id = 0;
    }

    return (


      <Query
      client={account_client}
      query={GET_PROFILE}
      variables={{profile_id:this.profile_id}}
      >
      {({ loading, error, data }) => {
        if (loading) return <div>Carregando...</div>;
        if (error) return <div>Erro :(</div>;
          
          this.profile_edit = data.profile;

        return (

      <Mutation mutation={ADD_TODO} client={account_client}>
      {(createOrUpdateProfile, { data }) => (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <div className="btn-top-container">
                <NavLink
                    to="/Profiles"
                    className="btn btn-info btn-fill"
                    activeClassName=""
                  >
                  VOLTAR
                </NavLink>
                <NotificationSystem ref="notificationSystem" style={style} />
              </div>
            
              <Card
                title="Perfil"
                content={
                  <form 
                  onSubmit={e => {
                    this.handleSubmit(e);
                    e.preventDefault();
                    createOrUpdateProfile({variables: { 
                      id: parseInt(this.profile_id), 
                      title: e.target.elements.title.value
                    } });
                  }}
                  >

                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "title",
                        label: "Título",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Título",
                        defaultValue: (this.profile_edit ? this.profile_edit.title : "")
                      }
                    ]}
                  />

                  <Row>
                    <Col md={10}>
                      <AlertInfo ref="alertSave" alertInfo={this.state.alertInfo} />
                    </Col>
                    <Col md={2}>
                      <Button bsStyle="info" pullRight fill type="submit">
                        Salvar
                      </Button>
                    </Col>
                  </Row>
                    <div className="clearfix" />
                  </form>
                }
              />

            </Col>
            
          </Row>
        </Grid>
      </div>
      )}
      </Mutation>

      );
      }}
      </Query>
    );
  }
}

export default ProfileItem;
