import React from 'react';
import './styles.css';

export default function Paginator(
    {
        currentPage = 0,
        pages = 0,
        onChangePage = null,
        buttons = 10
    }
) {

    let indents = [];
    let diferenceStart = 0;
    let diferenceEnd = 0;

    if (currentPage <= buttons / 2) {
        diferenceStart = (buttons / 2) - currentPage;
    }

    if (currentPage > pages - (buttons / 2)) {
        diferenceEnd = pages - (buttons / 2) - currentPage + 1;
    }

    for (let page_num = 1; page_num <= pages; page_num++) {
        if ((page_num <= currentPage + (buttons / 2) + diferenceStart) &&
            (page_num >= currentPage - (buttons / 2) + diferenceEnd)) {

            indents.push(
                <button
                    key={page_num}
                    className={`paginator-component-item ${+page_num === +currentPage && "paginator-component-item-selected"}`}
                    onClick={() => onChangePage(page_num)}>
                    {page_num}
                </button>
            );
        }
    }

    return (
        <div>
            {pages > 1 && (
                <div className='paginator-component'>
                    {currentPage > buttons / 2 + 1 && (
                        <div className='first-and-last'>
                            <button onClick={() => onChangePage(1)}
                                    className="paginator-component-item">{1}</button>
                            <span className="paginator-dots">...</span>
                        </div>
                    )}
                    <div className='buttons-paginator'>
                        {indents}
                    </div>
                    {currentPage < pages - (buttons / 2) && (
                        <div className='first-and-last'>
                            <span className="paginator-dots">...</span>
                            <button onClick={() => onChangePage(pages)}
                                    className="paginator-component-item">{pages}</button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );

};

