import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
} from "react-bootstrap";

import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import Select from 'react-select';
import DateTimePicker from 'react-datetime-picker';
import HttpLinkServices from "../../services/HttpLinkServices";
import Config from "../../config/config";

const dam_client = new ApolloClient({
  link: HttpLinkServices.dam,
  cache: new InMemoryCache(),
});

const ADD_TODO = gql`
  mutation createOrUpdateAsset($id: Int!, $title: String!, $active: Int!, $showgrid: Int!, $date_publish: String!, $date_unpublish: String!,  $directlink: String!){
    createOrUpdateAsset(id:$id, input:{title:$title, active:$active, showgrid:$showgrid, date_publish:$date_publish, date_unpublish:$date_unpublish, directlink:$directlink }){
      id
    }
  }
`;

const GET_ASSET = gql`
  query getAsset($dam_id: Int!){
    asset(id:$dam_id){
      id, title, directlink, thumbnail, active, showgrid, date_publish_format, date_unpublish_format, category{
        value:id,label:title
      }
    }
  }
`;

const GET_CATEGORY_HAS_ASSET = gql`
  query categoryHasAsset_collection($asset_id: Int!){
    categoryHasAsset_collection(
      asset_id: $asset_id
    ){
      category_has_asset{
        id
        category_id
      }
    }
  }
`;

const GET_CATEGORIES = gql`
{
  category_collections{
    categories{
      value:id
      label:title
    }
  }
}
`;


const CREATE_CATEGORY_HAS_ASSET = gql`
  mutation createCategoryHasAsset($asset_id: Int!, $category_id: Int!){
    createCategoryHasAsset(
      asset_id: $asset_id,
      category_id:$category_id
    ) {
      id
    }
  }
`;


const DELETE_CATEGORY_HAS_ASSET = gql`
mutation deleteCategoryHasAsset($asset_id: Int!, $category_id: Int!){
  deleteCategoryHasAsset(
    asset_id: $asset_id,
    category_id:$category_id
  ) {
      id
    }
  }
`;


class DamItem extends Component {

  dam_id;
  dam_edit;

  constructor() {
    super();
    this.state = {
      alertInfo: "",
      checkboxValue_active: false,
      checkboxValue_showgrid: false,
      datePublish: new Date(),
      dateUnpublish: null
    };
    
    this.start_publish_date = true;
    this.start_unpublish_date = true;
    this.start_active = true;
    this.start_showgrid = true;
    
  }

  onChange_datePublish = datePublish => this.setState({ datePublish })
  onChange_dateUnpublish = dateUnpublish => this.setState({ dateUnpublish })

  handleCheckboxChange = (e) => {
    this.setState(state => ({
      ...state,
      checkboxValue_active: !this.state.checkboxValue_active
    }));

  }

  handleCheckboxChange_showgrid = (e) => {
    this.setState(state => ({
      ...state,
      checkboxValue_showgrid: !this.state.checkboxValue_showgrid
    }));

  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      alertInfo: "Salvo com sucesso!"
    });

    setTimeout(() => {
      this.setState({
        alertInfo: ""
      })
    },3000);
  }


  onChange = (inputValue, action, item_id ) => {

    // Adicionar
    if(action.action === 'select-option') {
      dam_client.mutate({
        mutation: CREATE_CATEGORY_HAS_ASSET,
        variables: {
          asset_id: item_id, category_id: action.option.value, 
        }
      });
    }
    // Remover
    else if(action.action === 'remove-value') {
      dam_client.mutate({
        mutation: DELETE_CATEGORY_HAS_ASSET,
        variables: {
          asset_id: item_id, category_id: action.removedValue.value
        }
      });
    }
 
  } 
  
  render() {

    
    if(this.props.match.params.id) {
      this.dam_id = this.props.match.params.id;
    } else {
      this.dam_id = 0;
    }

    return (


      <Query
      client={dam_client}
      fetchPolicy='no-cache'
      query={GET_ASSET}
      variables={{dam_id:this.dam_id}}
      >
      {({ loading, error, data }) => {
        if (loading) return <div>Carregando...</div>;
        if (error) return <div>Erro :(</div>;
          
          this.dam_edit = data.asset;

          if(this.start_publish_date && this.dam_edit && this.dam_edit.date_publish_format) {
            this.start_publish_date = false;
            this.setState({
              datePublish: new Date(this.dam_edit.date_publish_format)
            })
          }
          if(this.start_unpublish_date && this.dam_edit && this.dam_edit.date_unpublish_format) {
            this.start_unpublish_date = false;
            this.setState({
              dateUnpublish: new Date(this.dam_edit.date_unpublish_format)
            })
          }
          if(this.start_active && this.dam_edit && this.dam_edit.active) {
            this.start_active = false;
            this.setState({
              checkboxValue_active: (this.dam_edit.active === "true" ? true : false)
            })
          }
          if(this.start_showgrid && this.dam_edit && this.dam_edit.showgrid) {
            this.start_showgrid = false;
            this.setState({
              checkboxValue_showgrid: (this.dam_edit.showgrid === 1 || this.dam_edit.showgrid === "true" ? true : false)
            })
          }
          
        return (

      <Mutation mutation={ADD_TODO} client={dam_client}>
      {(createOrUpdateDam, { data }) => (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <div className="btn-top-container">
                <NavLink
                    to={'/Dam/'+this.props.match.params.type}
                    className="btn btn-info btn-fill"
                    activeClassName=""
                  >
                  VOLTAR
                </NavLink>
                
                <NotificationSystem ref="notificationSystem" style={style} />
              </div>
            
              <Card
                title={this.props.match.params.type}
                content={
                  <form 
                  onSubmit={e => {
                    this.handleSubmit(e);
                    e.preventDefault();
                    createOrUpdateDam({variables: { 
                      id: this.dam_id, 
                      title: e.target.elements.title.value,
                      active: (e.target.elements.active.checked ? 1 : 0),
                      showgrid: (e.target.elements.showgrid.checked ? 1 : 0),
                      date_publish: e.target.elements.date_publish.value,
                      date_unpublish: e.target.elements.date_unpublish.value,
                      directlink: e.target.elements.directlink.value
                    } });
                  }}
                  >

                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "title",
                        label: "Título",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Título",
                        defaultValue: (this.dam_edit ? this.dam_edit.title : "")
                      }
                    ]}
                  />

                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "directlink",
                        label: "Link (em caso de banner)",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Link (em caso de banner)",
                        defaultValue: (this.dam_edit ? this.dam_edit.directlink : "")
                      }
                    ]}
                  />

                 

                  <hr />
                  <FormGroup>
                    <ControlLabel>
                      <input
                        value="1"
                        name="active"
                        type="checkbox"
                        checked={this.state.checkboxValue_active}
                        onChange={this.handleCheckboxChange}
                      /> Ativo
                    </ControlLabel>
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel>
                      <input
                        value="1"
                        name="showgrid"
                        type="checkbox"
                        checked={this.state.checkboxValue_showgrid}
                        onChange={this.handleCheckboxChange_showgrid}
                      /> Exibir fora das trilhas
                    </ControlLabel>
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel>Data de publicação</ControlLabel>
                    <div>
                      <DateTimePicker
                        name="date_publish"
                        onChange={this.onChange_datePublish}
                        value={this.state.datePublish}
                        required={true}
                        format='d/M/y H:m'
                        lang= 'pt'
                      />
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel>Data para despublicar</ControlLabel>
                    <div>
                      <DateTimePicker
                        name="date_unpublish"
                        onChange={this.onChange_dateUnpublish}
                        value={this.state.dateUnpublish}
                        required={false}
                        format='d/M/y H:m'
                        lang= 'pt'
                      />
                    </div>
                  </FormGroup>

                  <hr />

                  <Query
                          client={dam_client}
                          query={GET_CATEGORIES}
                        >
                          {({ loading, error, data }) => {
                            if (loading) return <tr><td>Carregando...</td></tr>;
                            if (error) return <tr><td>Erro :(</td></tr>;
                              
                              var categories = data.category_collections.categories;

                            return (
                                    <Query
                                    client={dam_client}
                                    query={GET_CATEGORY_HAS_ASSET}
                                    variables={{
                                      asset_id:this.dam_edit.id
                                    }}
                                    fetchPolicy='no-cache'
                                  >
                                    {({ loading, error, data }) => {
                                      if (loading) return <div>Carregando...</div>;
                                      if (error) return <div>Erro :(</div>;
                                        
                                        var categories_has_asset_collection_default = [];
                                        var categories_has_asset_collection = data.categoryHasAsset_collection.category_has_asset;
                                        categories.map((category_item) => {
                                          categories_has_asset_collection.map((categories_has_asset_item) => {
                                            if(category_item.value === categories_has_asset_item.category_id) {
                                              categories_has_asset_collection_default.push(category_item);
                                            }
                                          });
                                        });

                                      return (
                                        <FormGroup>
                                          <ControlLabel>Categorias</ControlLabel>
                                          <div>
                                          <Select
                                              defaultValue={categories_has_asset_collection_default}
                                              isMulti
                                              isSearchable
                                              name={"categories"}
                                              options={categories}
                                              className="basic-multi-select"
                                              classNamePrefix="select"
                                              //inputValue={inputValue}
                                              onChange={(inputValue, action ) => this.onChange(inputValue, action, this.dam_edit.id )}
                                              //onInputChange={this.onInputChange}
                                              //onFocus={() => this.setState({currentInputChange: item.id})}
                                              isClearable={true}
                                              //onInputChange={this.onInputChange}
                                            />
                                            </div>
                                          </FormGroup>
                                          );
                                        }}
                                      </Query>
                          );
                        }}
                      </Query>

                  
                  <Row>
                    <Col md={10}>
                      <AlertInfo ref="alertSave" alertInfo={this.state.alertInfo} />
                    </Col>
                    <Col md={2}>
                      <Button bsStyle="info" pullRight fill type="submit">
                        Salvar
                      </Button>
                    </Col>
                  </Row>
                    <div className="clearfix" />
                  </form>
                }
              />

            <Card
                title={this.dam_edit ?"Thumbnail":""}
                content={this.dam_edit ?
                  <form action={Config.baseUrl + "/api/v1/dam/dam_thumb"} method="post" enctype='multipart/form-data'>
                  <input type="hidden" name="returnUrl" value={"/#/dam-edit/"+this.props.match.params.type+"/"+this.dam_edit.id} />
                  <input type="hidden" name="id" value={this.dam_edit.id} />

                  <input
                  type="file"
                  name="file"
                  required
                  />

                  <Row>
                    <Col md={2}>
                      <Button bsStyle="info" pullRight fill type="submit">
                        Upload
                      </Button>
                    </Col>
                  </Row>
                    <div className="clearfix" />
                  </form>
              : <div></div>}
              />

              <Card
                title=""
                content={this.dam_edit && this.dam_edit.thumbnail ?
                  <div><img alt="thumb" src={Config.baseUrl + "/dam/assets/"+this.dam_edit.thumbnail} height="100px" /></div>
              : <div></div>}
              />
              
            </Col>
            
          </Row>
        </Grid>
      </div>
      )}
      </Mutation>

      );
      }}
      </Query>
    );
  }
}

export default DamItem;
