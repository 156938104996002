import React, { useState, useEffect } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { NavLink } from "react-router-dom";
import { Query } from "react-apollo";
import gql from "graphql-tag";

import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";

import Button from "../../components/CustomButton/CustomButton.jsx";
import "react-confirm-alert/src/react-confirm-alert.css";
import { confirmAlert } from "react-confirm-alert";
import HttpLinkServices from "../../services/HttpLinkServices";
import Paginator from "../../components/Paginator";

const trail_client = new ApolloClient({
  link: HttpLinkServices.lms,
  cache: new InMemoryCache(),
});

function getUrl($param, $param_1 = null, $param_2 = null) {
  var $url = "/#/" + $param;
  if ($param_1 != null) {
    $url += "/" + $param_1;
  }
  if ($param_2 != null) {
    $url += "/" + $param_2;
  }
  return $url;
}

const GET_TRAIL = gql`
  query getTrail(
    $search: String!
    $limit: Int!
    $page: Int!
    $order: String!
    $orderby: String!
  ) {
    trails_collections_dashboard(
      search: $search
      limit: $limit
      page: $page
      order: $order
      orderby: $orderby
    ) {
      trails {
        id
        title
      }
    }
  }
`;

const DELETE_TRAIL = gql`
  mutation deleteTrail($id: Int!) {
    deleteTrail(id: $id) {
      id
    }
  }
`;

export default function TrailList() {
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [orderby, setOrderby] = useState("id");
  const [order, setOrder] = useState("DESC");
  const [totalUsers, setTotalUsers] = useState(0);

  function toggleOrder() {
    setOrder(order == "DESC" ? "ASC" : "DESC");
  }

  function handleSubmit(event) {
    event.preventDefault();

    setSearch(event.target.elements.search.value);
    setOrder(event.target.elements.order.value);
    setOrderby(event.target.elements.orderby.value);
    setPage(1);
  }

  function countTrail() {
    trail_client
      .query({
        query: gql`
          query countTrail($search: String!) {
            trail_count(search: $search) {
              count
            }
          }
        `,
        variables: {
          search: search,
        },
      })
      .then((result) => setTotalUsers(result.data.trail_count.count));
  }

  useEffect(() => {
    countTrail();
  }, [search]);

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <div className="btn-top-container">
              <NavLink
                to={"/Trail-new/"}
                className="btn btn-info btn-fill"
                activeClassName=""
              >
                Novo Trilha
              </NavLink>
            </div>

            <Card
              title={"Trilhas ativos"}
              trail="Gerenciar trails ativos"
              ctTableFullWidth
              ctTableResponsive
              content={
                <Table striped hover>
                  <thead>
                    <tr>
                      <th colSpan={9}>
                        <form onSubmit={handleSubmit}>
                          <Row>
                            <Col md={6} lg={3}>
                              <input
                                type="text"
                                name="search"
                                className="form-control"
                              />
                            </Col>
                            <Col md={2}>
                              <Button bsStyle="info" fill type="submit">
                                Buscar
                              </Button>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6} lg={1}>
                              <select name="orderby" className="form-control">
                                <option value="id">ID</option>
                                <option value="title">Título</option>
                              </select>
                            </Col>
                            <Col md={6} lg={2}>
                              <select name="order" className="form-control">
                                <option value="DESC">Decrescente</option>
                                <option value="ASC" selected={order == "ASC"}>
                                  Crescente
                                </option>
                              </select>
                            </Col>
                            <Col md={2}>
                              <Button
                                bsStyle="info"
                                fill
                                type="button"
                                onClick={toggleOrder}
                              >
                                Ordenar
                              </Button>
                            </Col>
                          </Row>
                        </form>
                      </th>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Título</th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <Query
                      client={trail_client}
                      query={GET_TRAIL}
                      variables={{
                        search,
                        limit,
                        page,
                        order,
                        orderby,
                      }}
                      fetchPolicy="no-cache"
                    >
                      {({ loading, error, data }) => {
                        if (loading)
                          return (
                            <tr>
                              <td>Carregando...</td>
                            </tr>
                          );
                        if (error)
                          return (
                            <tr>
                              <td>Erro :(</td>
                            </tr>
                          );

                        if (!data.trails_collections_dashboard) {
                          return (
                            <tr>
                              <td>(Vazio)</td>
                            </tr>
                          );
                        }

                        return data.trails_collections_dashboard.trails.map((item) => (
                          <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.title}</td>

                            <td>
                              <a
                                className="btn btn-info"
                                href={getUrl("Trilha-relatorio", item.id)}
                              >
                                Gerar relatório
                              </a>
                            </td>
                            <td>
                              <a
                                className="btn btn-info"
                                href={getUrl("Trail-has-course", item.id)}
                              >
                                Cursos
                              </a>
                            </td>
                            <td>
                              <a
                                className="btn btn-info"
                                href={getUrl("Trail-edit", item.id)}
                              >
                                Editar
                              </a>
                            </td>
                            <td>
                              <a
                                className="btn btn-success"
                                href={getUrl("segmentation", "Trail", item.id)}
                              >
                                Segmentar
                              </a>
                            </td>
                            <td>
                              {
                               // Trecho de código comentado temporariamente, devido o sistema não tratar exclusão lógica.
                               /* <Button
                                bsStyle="danger"
                                onClick={() => {
                                  var r = confirmAlert({
                                    title: "",
                                    message:
                                      "Tem certeza que deseja excluir " +
                                      item.title,
                                    buttons: [
                                      {
                                        label: "Sim",
                                        onClick: () => {
                                          // Delete item
                                          trail_client
                                            .mutate({
                                              mutation: DELETE_TRAIL,
                                              variables: {
                                                id: item.id,
                                              },
                                            })
                                            .then((result) => {
                                              window.location.reload();
                                            });
                                        },
                                      },
                                      {
                                        label: "Não",
                                      },
                                    ],
                                  });
                                }}
                              >
                                Excluir
                              </Button> */}
                            </td>
                          </tr>
                        ));
                      }}
                    </Query>
                  </tbody>
                </Table>
              }
            />
          </Col>
          <Col sm={12}>
            <Paginator
              currentPage={page}
              pages={Math.ceil(totalUsers / limit)}
              onChangePage={(page) => setPage(page)}
              buttons={6}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}
