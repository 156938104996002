import Auth from "../views/Auth/Auth";

import Home from "../views/Home/Home";
import Dashboard from "../views/Dashboard/Dashboard";
import UserProfile from "../views/UserProfile/UserProfile";

import Profile from "../views/Profile/Profile";
import ProfileItem from "../views/Profile/ProfileItem";
import ProfileHasModule from "../views/Profile/ProfileHasModule";

import Segmentation from "../views/Segmentation/Segmentation";
import SegmentationImport from "../views/Segmentation/SegmentationImport";

import Cms from "../views/Cms/Cms";
import CmsItem from "../views/Cms/CmsItem";

import Dam from "../views/Dam/Dam";
import DamItem from "../views/Dam/DamItem";
import DamUpload from "../views/Dam/DamUpload";


import Category from "../views/Category/Category";
import SubCategory from "../views/Category/SubCategory";
import CategoryItem from "../views/Category/CategoryItem";

import CategoryCourse from "../views/CategoryCourse/Category";
import SubCategoryCourse from "../views/CategoryCourse/SubCategory";
import CategoryCourseItem from "../views/CategoryCourse/CategoryItem";

import UserList from "../views/User/UserList";
import UserItem from "../views/User/UserItem";
import UserCreateOne from '../views/User/CreateOne'
import UserCreateBatch from '../views/User/CreateBatch'

import CorretorList from "../views/Corretor/CadastroList";
import CorretorItem from "../views/Corretor/CadastroItem";

import ParceiroList from "../views/Parceiro/CadastroList";
import ParceiroItem from "../views/Parceiro/CadastroItem";

import ProofList from "../views/Course/ProofList";
import ProofItem from "../views/Course/ProofItem";

import QuestionList from "../views/Course/QuestionList";
import QuestionItem from "../views/Course/QuestionItem";

import AnswerList from "../views/Course/AnswerList";
import AnswerItem from "../views/Course/AnswerItem";

import Course from "../views/Course/Course";
import CourseItem from "../views/Course/CourseItem";
import CourseUpload from "../views/Course/CourseUpload";

import CoursePresentialList from "../views/Course/CoursePresentialList";
import CoursePresentialItem from "../views/Course/CoursePresentialItem";

import ClassroomList from "../views/Course/ClassroomList";
import ClassroomItem from "../views/Course/ClassroomItem";
import Subscription from "../views/Course/Subscription";

import Trail from "../views/Trail/Trail";
import TrailItem from "../views/Trail/TrailItem";
import FiltroRelatorioTrilha from "../views/Trail/FiltroRelatorioTrilha";

import Chat from "../views/Chat/Chat";
import ChatItem from "../views/Chat/ChatItem";
import ChatModerate from "../views/Chat/Moderate";
import ChatCalendar from "../views/Chat/Calendar";

import TableList from "../views/TableList/TableList";
import Typography from "../views/Typography/Typography";
import Icons from "../views/Icons/Icons";
import Maps from "../views/Maps/Maps";
import Notifications from "../views/Notifications/Notifications";
import TrailHasCourse from "../views/Trail/TrailHasCourse";

import BranchList from '../views/Branch/BranchList';
import BranchItem from '../views/Branch/BranchItem';

import InstrutorList from '../views/Instrutor/InstrutorList';
import InstrutorItem from '../views/Instrutor/InstrutorItem';

/* REPORTS */
import ProgramInProgress from "../views/Reports/ProgramInProgress";
import CreateBatch from "../views/User/CreateBatch";

const dashboardRoutes = [

  /* ASSETS GERAL */

  {
    showSidebar: false,
    path: "/Dam/:type/:external_parent_id",
    name: "Item",
    icon: "pe-7s-albums",
    component: Dam
  },
  {
    showSidebar: false,
    path: "/Dam-edit/:type/:id",
    name: "Item",
    icon: "pe-7s-albums",
    component: DamItem
  },
  {
    showSidebar: false,
    path: "/Dam-new/:type/:external_parent_id",
    name: "Item",
    icon: "pe-7s-albums",
    component: DamUpload
  },
  {
    showSidebar: false,
    path: "/Dam-new/:type",
    name: "Item",
    icon: "pe-7s-albums",
    component: DamUpload
  },
  {
    showSidebar: false,
    path: "/dam-edit-file/:type/:id",
    name: "Item",
    icon: "pe-7s-albums",
    component: DamUpload
  },


  {
    default: true,
    showSidebar: true,
    path: "/home",
    name: "Início",
    icon: "pe-7s-home",
    component: Home
  },
  {
    showSidebar: true,
    path: "/dashboard",
    name: "Dashboard",
    icon: "pe-7s-graph",
    component: Dashboard,
    module: "Dashboard"
  },
  {
    showSidebar: false,
    path: "/auth/:userid",
    name: "Auth",
    icon: "pe-7s-graph",
    component: Auth
  },
  {
    showSidebar: false,
    path: "/profile",
    name: "User Profile",
    icon: "pe-7s-user",
    component: UserProfile
  },

  {
    showSidebar: false,
    path: "/segmentation/:module_name/:id",
    name: "Segmentation",
    icon: "pe-7s-user",
    component: Segmentation
  },
  {
    showSidebar: false,
    path: "/segmentation-import/:module_name/:id",
    name: "SegmentationImport",
    icon: "pe-7s-user",
    component: SegmentationImport
  },


  /* USUÁRIOS */
  {
    showSidebar: true,
    path: "/User",
    name: "Usuários",
    icon: "pe-7s-users",
    component: UserList,
    module: "User"
  },
  {
    showSidebar: false,
    path: "/User-edit/:id",
    name: "User Edit",
    icon: "pe-7s-users",
    component: UserItem
  },
  {
    showSidebar: false,
    path: "/User-create-one",
    name: "User Create Single",
    icon: "pe-7s-users",
    component: UserCreateOne
  },
  {
    showSidebar: false,
    path: "/User-create-batch",
    name: "User Create batch (by file)",
    icon: "pe-7s-users",
    component: UserCreateBatch
  },
  /*IMPORTAR BASE*/
  {
    showSidebar: true,
    path: "/Importar-base",
    name: "Importar Base",
    icon: "pe-7s-upload",
    component: CreateBatch,
    module: "ImportarBase"
  },

  /* PERFIS */
  {
    showSidebar: true,
    path: "/Profiles",
    name: "Perfis Admin",
    icon: "pe-7s-users",
    component: Profile,
    module: "Profile"
  },
  {
    showSidebar: false,
    path: "/Profile-new",
    name: "Profile Item",
    icon: "pe-7s-menu",
    component: ProfileItem
  },
  {
    showSidebar: false,
    path: "/Profile-edit/:id",
    name: "Profile Edit",
    icon: "pe-7s-menu",
    component: ProfileItem
  },
  {
    showSidebar: false,
    path: "/Profile-has-module/:id",
    name: "Profile",
    icon: "pe-7s-menu",
    component: ProfileHasModule
  },

  /* CORRETORES */
  {
    showSidebar: true,
    path: "/Corretor",
    name: "SSO Corretores",
    icon: "pe-7s-users",
    component: CorretorList,
    module: "Corretor"
  },
  {
    showSidebar: false,
    path: "/Corretor-edit/:id",
    name: "Corretor Edit",
    icon: "pe-7s-users",
    component: CorretorItem
  },

  {
    showSidebar: true,
    path: "/Parceiro",
    name: "SSO Parceiros",
    icon: "pe-7s-users",
    component: ParceiroList,
    module: "Parceiro"
  },
  {
    showSidebar: false,
    path: "/Parceiro-edit/:id",
    name: "Parceiro Edit",
    icon: "pe-7s-users",
    component: ParceiroItem
  },

  /* RAMOS DE ATUAÇÃO */
  {
    showSidebar: true,
    path: "/Branch",
    name: "Ramos de atuação",
    icon: "pe-7s-users",
    component: BranchList,
    module: "Branch"
  },
  {
    showSidebar: false,
    path: "/Branch-new",
    name: "Branch New",
    icon: "pe-7s-users",
    component: BranchItem
  },
  {
    showSidebar: false,
    path: "/Branch-edit/:id",
    name: "Branch Edit",
    icon: "pe-7s-users",
    component: BranchItem
  },

  /* CATEGORIAS DE CURSOS
  {
    showSidebar: true,
    path: "/course-category",
    name: "Categorias dos Cursos",
    icon: "pe-7s-menu",
    component: CategoryCourse,
    module: "Category"
  },
  {
    showSidebar: false,
    path: "/course-subcategory/:parent_id",
    name: "Subcategorias",
    icon: "pe-7s-menu",
    component: SubCategoryCourse
  },
  {
    showSidebar: false,
    path: "/course-category-new",
    name: "Category Item",
    icon: "pe-7s-menu",
    component: CategoryCourseItem
  },
  {
    showSidebar: false,
    path: "/course-subcategory-new/:parent_id",
    name: "Category Item",
    icon: "pe-7s-menu",
    component: CategoryCourseItem
  },
  {
    showSidebar: false,
    path: "/course-category-edit/:id",
    name: "Category Edit",
    icon: "pe-7s-menu",
    component: CategoryCourseItem
  },
*/

  /* CATEGORIAS */
  {
    showSidebar: true,
    path: "/Category",
    name: "Categorias",
    icon: "pe-7s-menu",
    component: Category,
    module: "Category"
  },
  {
    showSidebar: false,
    path: "/SubCategory/:parent_id",
    name: "Subcategorias",
    icon: "pe-7s-menu",
    component: SubCategory
  },
  {
    showSidebar: false,
    path: "/Category-new",
    name: "Category Item",
    icon: "pe-7s-menu",
    component: CategoryItem
  },
  {
    showSidebar: false,
    path: "/SubCategory-new/:parent_id",
    name: "Category Item",
    icon: "pe-7s-menu",
    component: CategoryItem
  },
  {
    showSidebar: false,
    path: "/Category-edit/:id",
    name: "Category Edit",
    icon: "pe-7s-menu",
    component: CategoryItem
  },

  /* CONTENT */
  {
    showSidebar: false,
    path: "/Cms-new/:module_name",
    name: "Item",
    icon: "pe-7s-albums",
    component: CmsItem
  },
  {
    showSidebar: false,
    path: "/Cms-edit/:module_name/:id",
    name: "Item",
    icon: "pe-7s-albums",
    component: CmsItem
  },


  /* CHAT */
  {
    showSidebar: true,
    path: "/Dam/:type",
    link: "/Dam/chatBanner",
    name: "Banner Sala Virt.",
    icon: "pe-7s-albums",
    component: Dam,
    module: "ChatBanner"
  },
  {
    showSidebar: true,
    path: "/Chat",
    name: "Sala virtual",
    icon: "pe-7s-chat",
    component: Chat,
    module: "Chat"
  },
  {
    showSidebar: false,
    path: "/Chat-new",
    name: "Chat Item",
    icon: "pe-7s-chat",
    component: ChatItem
  },
  {
    showSidebar: false,
    path: "/Chat-edit/:id",
    name: "Chat Item",
    icon: "pe-7s-chat",
    component: ChatItem
  },
  {
    showSidebar: false,
    path: "/Moderate/:id",
    name: "Chat Moderate",
    icon: "pe-7s-chat",
    component: ChatModerate
  },
  {
    showSidebar: false,
    path: "/Chat-Calendar/",
    name: "Chat Calendar",
    icon: "pe-7s-chat",
    component: ChatCalendar
  },
  {
    showSidebar: false,
    path: "/Chat-Calendar/",
    name: "Chat Calendar",
    icon: "pe-7s-chat",
    component: ChatCalendar
  },


  /* MEDIA CENTER */
  {
    showSidebar: true,
    path: "/Dam/:type",
    link: "/Dam/Media",
    name: "Conteúdos digitais",
    icon: "pe-7s-photo",
    component: Dam,
    module: "Media"
  },


  /* CMS GERAL */
  {
    showSidebar: false,
    path: "/Cms-edit/:module_name/:id",
    name: "Item",
    icon: "pe-7s-albums",
    component: CmsItem
  },


  /* DEPOIMENTOS */
  {
    showSidebar: true,
    path: "/Cms/:module_name",
    link: "/Cms/Testimony",
    name: "Destaques",
    icon: "pe-7s-star",
    component: Cms,
    module: "Testimony"
  },

  /* BANNER */
  {
    showSidebar: true,
    path: "/Dam/:type",
    link: "/Dam/Banner",
    name: "Banner",
    icon: "pe-7s-albums",
    component: Dam,
    module: "Banner"
  },

  /* Avaliação e Avaliação de reação Edit */
  {
    showSidebar: false,
    path: "/Proof-new/:type_id",
    name: "Proof",
    icon: "pe-7s-note",
    component: ProofItem
  },
  {
    showSidebar: false,
    path: "/Proof-edit/:type_id/:id",
    name: "Proof",
    icon: "pe-7s-note",
    component: ProofItem
  },
  {
    showSidebar: false,
    path: "/Question/:type_id/:proof_id",
    name: "Question",
    icon: "pe-7s-note",
    component: QuestionList,
    module: "Proof"
  },
  {
    showSidebar: false,
    path: "/Question-new/:type_id/:proof_id",
    name: "Question",
    icon: "pe-7s-note",
    component: QuestionItem
  },
  {
    showSidebar: false,
    path: "/Question-edit/:type_id/:proof_id/:id",
    name: "Question",
    icon: "pe-7s-note",
    component: QuestionItem
  },

  {
    showSidebar: false,
    path: "/Answer/:type_id/:proof_id/:question_id",
    name: "Answer",
    icon: "pe-7s-note",
    component: AnswerList,
    module: "Proof"
  },
  {
    showSidebar: false,
    path: "/Answer-new/:type_id/:proof_id/:question_id",
    name: "Answer",
    icon: "pe-7s-note",
    component: AnswerItem
  },
  {
    showSidebar: false,
    path: "/Answer-edit/:type_id/:proof_id/:question_id/:id",
    name: "Answer",
    icon: "pe-7s-note",
    component: AnswerItem
  },

  /* Avaliação */
  {
    showSidebar: true,
    path: "/Proof/:type_id",
    link: "/Proof/1",
    name: "Avaliação",
    icon: "pe-7s-note",
    component: ProofList,
    module: "Proof"
  },

  /* Avaliação de Reação */
  {
    showSidebar: true,
    path: "/Proof/:type_id",
    link: "/Proof/2",
    name: "Aval. de reação",
    icon: "pe-7s-note",
    component: ProofList,
    module: "Proof"
  },

  /* Quiz */
  {
    showSidebar: true,
    path: "/Proof/:type_id",
    link: "/Proof/3",
    name: "Quiz",
    icon: "pe-7s-note",
    component: ProofList,
    module: "Proof"
  },

  /* INSTRUTORES */
  {
    showSidebar: true,
    path: "/Instrutor",
    name: "Instrutores",
    icon: "pe-7s-users",
    component: InstrutorList,
    module: "Instrutor"
  },
  {
    showSidebar: false,
    path: "/Instrutor-new",
    name: "Instrutor New",
    icon: "pe-7s-users",
    component: InstrutorItem
  },
  {
    showSidebar: false,
    path: "/Instrutor-edit/:id",
    name: "Instrutor Edit",
    icon: "pe-7s-users",
    component: InstrutorItem
  },

  /* CURSOS PRESENCIAIS */
  {
    showSidebar: true,
    path: "/Presential-course",
    name: "Cursos Presenciais",
    icon: "pe-7s-study",
    component: CoursePresentialList,
    module: "Course"
  },
  {
    showSidebar: false,
    path: "/Presential-course-new",
    name: "Course Item",
    icon: "pe-7s-menu",
    component: CoursePresentialItem
  },
  {
    showSidebar: false,
    path: "/Presential-course-edit/:id",
    name: "Course Presential Edit",
    icon: "pe-7s-menu",
    component: CoursePresentialItem
  },

  /* TURMAS PRESENCIAIS */
  {
    showSidebar: true,
    path: "/Classroom",
    name: "Turmas Presenciais",
    icon: "pe-7s-study",
    component: ClassroomList,
    module: "Classroom"
  },
  {
    showSidebar: false,
    path: "/Classroom-new",
    name: "Turma Item",
    icon: "pe-7s-menu",
    component: ClassroomItem
  },
  {
    showSidebar: false,
    path: "/Classroom-edit/:id",
    name: "Turma Presential Edit",
    icon: "pe-7s-menu",
    component: ClassroomItem
  },
  {
    showSidebar: false,
    path: "/Classroom-subscription/:classroom_id",
    name: "Turma Presential Edit",
    icon: "pe-7s-menu",
    component: Subscription
  },

  /* CURSOS */
  {
    showSidebar: true,
    path: "/Course",
    name: "Cursos On-line",
    icon: "pe-7s-study",
    component: Course,
    module: "Course"
  },
  {
    showSidebar: false,
    path: "/Course-new-scorm",
    name: "Course Item",
    icon: "pe-7s-menu",
    component: CourseUpload
  },
  {
    showSidebar: false,
    path: "/Course-edit-scorm/:id",
    name: "Course Item",
    icon: "pe-7s-menu",
    component: CourseUpload
  },
  {
    showSidebar: false,
    path: "/Course-new-video",
    name: "Course Item",
    icon: "pe-7s-menu",
    component: CourseItem
  },
  {
    showSidebar: false,
    path: "/Course-edit/:id",
    name: "Course Edit",
    icon: "pe-7s-menu",
    component: CourseItem
  },

  /* ASSETS DE CONTEÚDOS EXTERNOS, COMO OS ASSETS DE CURSOS */
  {
    showSidebar: false,
    path: "/DamCourse/:type/:external_parent_id",
    name: "Banner",
    icon: "pe-7s-albums",
    component: Dam,
    module: "Banner"
  },
  {
    showSidebar: false,
    path: "/DamCourse-new/:type/:external_parent_id",
    name: "Item",
    icon: "pe-7s-albums",
    component: DamUpload
  },
  {
    showSidebar: false,
    path: "/DamCourse-edit/:type/:external_parent_id/:id",
    name: "Item",
    icon: "pe-7s-albums",
    component: DamItem
  },

  /* TRILHA */
  {
    showSidebar: true,
    path: "/trail",
    name: "Trilhas",
    icon: "pe-7s-next-2",
    component: Trail,
    module: "Trail"
  },
  {
    showSidebar: false,
    path: "/Trail-new",
    name: "Trail Item",
    icon: "pe-7s-menu",
    component: TrailItem
  },
  {
    showSidebar: false,
    path: "/Trail-edit/:id",
    name: "Trail Edit",
    icon: "pe-7s-menu",
    component: TrailItem
  },
  {
    showSidebar: false,
    path: "/Trilha-relatorio/:id",
    name: "Relatorio Trilha",
    icon: "pe-7s-menu",
    component: FiltroRelatorioTrilha
  },
  {
    showSidebar: false,
    path: "/Trail-has-course/:id",
    name: "Trail",
    icon: "pe-7s-menu",
    component: TrailHasCourse
  },

  /* RELATORIOS */
  {
    showSidebar: false,
    path: "/ser",
    name: "Relatórios",
    icon: "pe-7s-users",
    component: Chat
  },
  /* OUTROS */
  {
    showSidebar: false,
    path: "/reports/program-in-progress",
    name: "Usuários",
    icon: "pe-7s-users",
    component: ProgramInProgress
  },
  {
    showSidebar: false,
    path: "/table",
    name: "Table List",
    icon: "pe-7s-note2",
    component: TableList
  },
  {
    showSidebar: false,
    path: "/typography",
    name: "Typography",
    icon: "pe-7s-news-paper",
    component: Typography
  },
  { showSidebar: false, path: "/icons", name: "Icons", icon: "pe-7s-science", component: Icons },
  { showSidebar: false, path: "/maps", name: "Maps", icon: "pe-7s-map-marker", component: Maps },
  {
    showSidebar: false,
    path: "/notifications",
    name: "Notifications",
    icon: "pe-7s-bell",
    component: Notifications
  },
  { redirect: true, path: "/", to: "/home", name: "Home" }
];

export default dashboardRoutes;
