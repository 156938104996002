import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel
} from "react-bootstrap";

import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import HttpLinkServices from "../../services/HttpLinkServices";

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import { requestVideosSearch } from '../../services/RequestSambaVideos';

import Select from 'react-select';
import DateTimePicker from 'react-datetime-picker';
import Config from "../../config/config";

const lms_client = new ApolloClient({
  link: HttpLinkServices.lms,
  cache: new InMemoryCache(),
});

const dam_client = new ApolloClient({
  link: HttpLinkServices.dam,
  cache: new InMemoryCache(),
});

const ADD_TODO = gql`
  mutation createOrUpdateCourse($id: Int!, $title: String!, $description: String!, $video: String!, $active: Int!, $showgrid: Int!, $date_publish: String!, $date_unpublish: String!,  $reactionevaluation_id: Int!, $proof_id: Int!, $responsible: String!, $hours: Int!, $minutes: Int!, $code: String!){
    createOrUpdateCourse(id:$id, input:{title:$title, description:$description, video:$video, active:$active,  showgrid:$showgrid, date_publish:$date_publish, date_unpublish:$date_unpublish, type:"online", reactionevaluation_id:$reactionevaluation_id, proof_id:$proof_id, responsible:$responsible, hours:$hours, minutes:$minutes, code:$code}){
      id
    }
  }
`;

const GET_COURSE = gql`
  query getCourse($course_id: Int!){
    course(id:$course_id){
      id, title, description, thumbnail, active, showgrid,responsible,  date_publish_format, date_unpublish_format, hours, minutes, code, video{
        value:id, label:title
      }, reactionevaluation{
        value:id, label:title
      }, proof{
        value:id, label:title
      }
    }
  }
`;

const GET_VIDEOS = gql`
{
  videos_collections{
    videos{
      value:id, label:title
    }
  }
}
`;


const GET_PROOFS = gql`query getProofs($type_id: Int!){
  proof_collections(type_id: $type_id){
    proofs{
      value:id, label:title
    }
  }
}
`;

const GET_CATEGORY_HAS_COURSE = gql`
  query categoryHasCourse_collection($course_id: Int!){
    categoryHasCourse_collection(
      course_id: $course_id
    ){
      category_has_course{
        id
        category_id
      }
    }
  }
`;

const CREATE_CATEGORY_HAS_COURSE = gql`
  mutation createCategoryHasCourse($course_id: Int!, $category_id: Int!){
    createCategoryHasCourse(
      course_id: $course_id,
      category_id:$category_id
    ) {
      id
    }
  }
`;


const DELETE_CATEGORY_HAS_COURSE = gql`
mutation deleteCategoryHasCourse($course_id: Int!, $category_id: Int!){
  deleteCategoryHasCourse(
    course_id: $course_id,
    category_id:$category_id
  ) {
      id
    }
  }
`;

const GET_CATEGORIES = gql`
{
  category_collections{
    categories{
      value:id
      label:title
    }
  }
}
`;


class CourseItem extends Component {

  course_id;
  course_edit;

  constructor() {
    super();
    this.state = {
      alertInfo: "",
      datePublish: new Date(),
      dateUnpublish: null,
      videoSearchData: [],
    };


    this.start_publish_date = true;
    this.start_unpublish_date = true;
    this.start_active = true;
    this.start_showgrid = true;
    
    
  }

  onChange_datePublish = datePublish => this.setState({ datePublish })
  onChange_dateUnpublish = dateUnpublish => this.setState({ dateUnpublish })

  onChange = (inputValue, action, item_id ) => {

    // Adicionar
    if(action.action === 'select-option') {
      lms_client.mutate({
        mutation: CREATE_CATEGORY_HAS_COURSE,
        variables: {
          course_id: item_id, category_id: action.option.value, 
        }
      });
    }
    // Remover
    else if(action.action === 'remove-value') {
      lms_client.mutate({
        mutation: DELETE_CATEGORY_HAS_COURSE,
        variables: {
          course_id: item_id, category_id: action.removedValue.value
        }
      });
    }
 
  } 

  handleCheckboxChange = (e) => {
    this.setState(state => ({
      ...state,
      checkboxValue_active: !this.state.checkboxValue_active
    }));

  }

  handleCheckboxChange_showgrid = (e) => {
    this.setState(state => ({
      ...state,
      checkboxValue_showgrid: !this.state.checkboxValue_showgrid
    }));

  }

  getSambaVideos = async (event) => {
    // Caso as teclas de ação (Shift, Control) sejam pressionadas, o evento de request não será acionado.
    if(event.key.length > 1 && event.key !== "Backspace") {
      return;
    }
  
    const videos = await requestVideosSearch(event.target.value);

    this.setState({
      videoSearchData: videos.data.map(({ id, title }) => ({
        __typename: "Video",
        label: title,
        value: id,
      })),
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({
      alertInfo: "Salvo com sucesso!"
    });

    setTimeout(() => {
      this.setState({
        alertInfo: ""
      })
    },3000);
  }


  
  render() {
    if(this.props.match.params.id) {
      this.course_id = parseInt(this.props.match.params.id);
    } else {
      this.course_id = 0;
    }

    return (
      <Query
      client={lms_client}
      query={GET_COURSE}
      variables={{course_id:this.course_id}}
      fetchPolicy='no-cache'
      >
      {({ loading, error, data }) => {
        if (loading) return <div>Carregando...</div>;
        if (error) return <div>Erro :(</div>;
          
          this.course_edit = data.course;

          if(this.start_publish_date && this.course_edit && this.course_edit.date_publish_format) {
            this.start_publish_date = false;
            this.setState({
              datePublish: new Date(this.course_edit.date_publish_format)
            })
          }

          if(this.start_unpublish_date && this.course_edit && this.course_edit.date_unpublish_format) {
            this.start_unpublish_date = false;
            this.setState({
              dateUnpublish: new Date(this.course_edit.date_unpublish_format)
            })
          }

          if(this.start_active && this.course_edit && this.course_edit.active) {
            this.start_active = false;
            this.setState({
              checkboxValue_active: (this.course_edit.active === 1 ? true : false)
            })
          }

          if(this.start_showgrid && this.course_edit && this.course_edit.showgrid) {
            this.start_showgrid = false;
            this.setState({
              checkboxValue_showgrid: (this.course_edit.showgrid === 1 ? true : false)
            })
          }

        return (
      <Mutation mutation={ADD_TODO} client={lms_client}>
      {(createOrUpdateCourse, { data }) => (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <div className="btn-top-container">
                <NavLink
                    to="/Course"
                    className="btn btn-info btn-fill"
                    activeClassName=""
                  >
                  VOLTAR
                </NavLink>
                
                <NotificationSystem ref="notificationSystem" style={style} />
              </div>
            
              <Card
                title="Curso"
                content={
                  <form 
                  onSubmit={e => {
                    this.handleSubmit(e);
                    e.preventDefault();
                    createOrUpdateCourse({variables: { 
                      id: this.course_id, 
                      title: e.target.elements.title.value,
                      description: e.target.elements.description.value,
                      video: (e.target.elements.video && e.target.elements.video.value ? e.target.elements.video.value : ""),
                      active: (e.target.elements.active.checked ? 1 : 0),
                      showgrid: (e.target.elements.showgrid.checked ? 1 : 0),
                      date_publish: e.target.elements.date_publish.value,
                      date_unpublish: e.target.elements.date_unpublish.value,
                      reactionevaluation_id: (e.target.elements.reactionevaluation_id.value ? parseInt(e.target.elements.reactionevaluation_id.value) : 0),
                      proof_id: (e.target.elements.proof_id.value ? parseInt(e.target.elements.proof_id.value) : 0),
                      responsible: e.target.elements.responsible.value,
                      hours: parseInt(e.target.elements.hours.value),
                      minutes: parseInt(e.target.elements.minutes.value),
                      code: e.target.elements.code.value
                    } });
                  }}
                  >

                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "title",
                        label: "Título",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Título",
                        defaultValue: (this.course_edit ? this.course_edit.title : "")
                      }
                    ]}
                  />
                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "description",
                        label: "Descrição",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Descrição",
                        defaultValue: (this.course_edit ? this.course_edit.description : "")
                      }
                    ]}
                  />
                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "hours",
                        label: "Carga horária (hrs)",
                        type: "number",
                        bsClass: "form-control",
                        placeholder: "Carga horária (hrs)",
                        defaultValue: (this.course_edit ? this.course_edit.hours : "")
                      }
                    ]}
                  />
                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "minutes",
                        label: "Carga horária (min)",
                        type: "number",
                        bsClass: "form-control",
                        placeholder: "Carga horária (min)",
                        defaultValue: (this.course_edit ? this.course_edit.minutes : "")
                      }
                    ]}
                  />
                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "responsible",
                        label: "Responsável",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Responsável",
                        defaultValue: (this.course_edit ? this.course_edit.responsible : "")
                      }
                    ]}
                  />

                  <FormGroup>
                    <ControlLabel>Vídeo</ControlLabel>
                    <div>
                      <Select
                        name="video"
                        options={this.state.videoSearchData}
                        className="basic-select"
                        classNamePrefix="select"
                        isClearable={true}
                        defaultValue={this.course_edit && this.course_edit ? this.course_edit.video : ""}
                        onKeyDown={(event) => this.getSambaVideos(event)}
                      />
                    </div>
                  </FormGroup>


                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "code",
                        label: "Código do curso",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Código do curso",
                        defaultValue: (this.course_edit ? this.course_edit.code : "")
                      }
                    ]}
                  />

                  <FormGroup>
                  <ControlLabel>Prova</ControlLabel>
                    <div>
                      <Query
                          variables={{type_id: 1}} // 1 é o ID da prova no microseviço de avalações
                          client={lms_client}
                          query={GET_PROOFS}
                          >
                          {({ loading, error, data }) => {
                            if (loading) return <div>Carregando...</div>;
                            if (error) return <div>Erro :(</div>;
                              var proofs = data;
                            return (
                                <Select
                                  name="proof_id"
                                  options={proofs.proof_collections ? proofs.proof_collections.proofs : []}
                                  className="basic-select"
                                  classNamePrefix="select"
                                  isClearable={true}
                                  defaultValue={this.course_edit && this.course_edit.proof ? this.course_edit.proof : ""}
                                />
                          );
                        }}
                      </Query>
                    </div>
                  </FormGroup>

                  <FormGroup>
                  <ControlLabel>Avaliação de reação</ControlLabel>
                    <div>
                      <Query
                          variables={{type_id: 2}} // 2 é o ID da avaliçaão de reação no microseviço de avalações
                          client={lms_client}
                          query={GET_PROOFS}
                          >
                          {({ loading, error, data }) => {
                            if (loading) return <div>Carregando...</div>;
                            if (error) return <div>Erro :(</div>;
                              var proofs = data;
                            return (
                                <Select
                                  name="reactionevaluation_id"
                                  options={proofs.proof_collections ? proofs.proof_collections.proofs : []}
                                  className="basic-select"
                                  classNamePrefix="select"
                                  isClearable={true}
                                  defaultValue={this.course_edit && this.course_edit.reactionevaluation ? this.course_edit.reactionevaluation : ""}
                                />
                          );
                        }}
                      </Query>
                    </div>
                  </FormGroup>

                  <hr />

                  <FormGroup>
                  <ControlLabel>Categorias</ControlLabel>
                  {
                    !this.course_edit || !this.course_edit.id 
                    ?
                      <div>
                        <strong>(Salve o curso, e edite novamente para definir as categorias)</strong>
                      </div>
                      
                    :
                    <div>
                      <Query
                              client={dam_client}
                              query={GET_CATEGORIES}
                            >
                              {({ loading, error, data }) => {
                                if (loading) return <tr><td>Carregando...</td></tr>;
                                if (error) return <tr><td>Erro :(</td></tr>;
                                  
                                  var categories = data.category_collections.categories;

                                return (

                                  <Query
                                      client={lms_client}
                                      query={GET_CATEGORY_HAS_COURSE}
                                      variables={{
                                        course_id:this.course_edit.id
                                      }}
                                      fetchPolicy='no-cache'
                                    >
                                      {({ loading, error, data }) => {
                                        if (loading) return <div>Carregando...</div>;
                                        if (error) return <div>Erro :(</div>;
                                          
                                          var categories_has_course_collection_default = [];
                                          var categories_has_course_collection = data.categoryHasCourse_collection.category_has_course;
                                          categories.map((category_item) => {
                                            categories_has_course_collection.map((categories_has_course_item) => {
                                              if(category_item.value === categories_has_course_item.category_id) {
                                                categories_has_course_collection_default.push(category_item);
                                              }
                                            });
                                          });

                                        return (
                                          <Select
                                              defaultValue={categories_has_course_collection_default}
                                              isMulti
                                              isSearchable
                                              name={"categories"}
                                              options={categories}
                                              className="basic-multi-select"
                                              classNamePrefix="select"
                                              //inputValue={inputValue}
                                              onChange={(inputValue, action ) => this.onChange(inputValue, action, this.course_edit.id )}
                                              //onInputChange={this.onInputChange}
                                              //onFocus={() => this.setState({currentInputChange: item.id})}
                                              isClearable={true}
                                              //onInputChange={this.onInputChange}
                                            />
                                            );
                                          }}
                                        </Query>


                                  );
                                }}
                              </Query>
                        </div>

                  }
                    
                  </FormGroup>

                  <hr />
                  <FormGroup>
                    <ControlLabel>
                      <input
                        value="1"
                        name="active"
                        type="checkbox"
                        checked={this.state.checkboxValue_active}
                        onChange={this.handleCheckboxChange}
                      /> Ativo
                    </ControlLabel>
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel>
                      <input
                        value="1"
                        name="showgrid"
                        type="checkbox"
                        checked={this.state.checkboxValue_showgrid}
                        onChange={this.handleCheckboxChange_showgrid}
                      /> Exibir fora das trilhas
                    </ControlLabel>
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel>Data de publicação</ControlLabel>
                    <div>
                      <DateTimePicker
                        name="date_publish"
                        onChange={this.onChange_datePublish}
                        value={this.state.datePublish}
                        required={true}
                        format='d/M/y H:m'
                        lang= 'pt'
                      />
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel>Data para despublicar</ControlLabel>
                    <div>
                      <DateTimePicker
                        name="date_unpublish"
                        onChange={this.onChange_dateUnpublish}
                        value={this.state.dateUnpublish}
                        required={false}
                        format='d/M/y H:m'
                        lang= 'pt'
                      />
                    </div>
                  </FormGroup>

                  
                  <Row>
                    <Col md={10}>
                      <AlertInfo ref="alertSave" alertInfo={this.state.alertInfo} />
                    </Col>
                    <Col md={2}>
                      <Button bsStyle="info" pullRight fill type="submit">
                        Salvar
                      </Button>
                    </Col>
                  </Row>
                    <div className="clearfix" />
                  </form>
                }
              />

            <Card
                title={this.course_edit ?"Thumbnail":""}
                content={this.course_edit ?
                  <form action={Config.baseUrl + "/api/v1/lms/course_thumb"}method="post" enctype='multipart/form-data'>
                  
                  <input type="hidden" name="returnUrl" value={"/#/Course-edit/"+this.course_edit.id} />
                  <input type="hidden" name="id" value={this.course_edit.id} />

                  <input
                  type="file"
                  name="file"
                  required
                  />

                  <Row>
                    <Col md={2}>
                      <Button bsStyle="info" pullRight fill type="submit">
                        Upload
                      </Button>
                    </Col>
                  </Row>
                    <div className="clearfix" />
                  </form>
              : <div/>}
              />

              <Card
                title=""
                content={this.course_edit && this.course_edit.thumbnail ?
                  <div><img alt="thumb" src={Config.baseUrl + "/lms/assets/"+this.course_edit.thumbnail} height="100px" /></div>
              : <div/>}
              />
              
            </Col>
            
          </Row>
        </Grid>
      </div>
      )}
      </Mutation>

      );
      }}
      </Query>
    );
  }
}

export default CourseItem;
