import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { Grid, Row, Col, Table } from "react-bootstrap";
import swal from "sweetalert";

import LmsService from "../../services/LmsService";

import Button from "../../components/CustomButton/CustomButton.jsx";
import Select from "../../components/Select";
import Paginator from "../../components/Paginator";
import Card from "../../components/Card/Card.jsx";

import "react-confirm-alert/src/react-confirm-alert.css";

const lmsService = new LmsService();

export default function UserList() {
  const [instrutores, setInstrutores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [page, setPage] = useState(1);
  const [totalInstrutores, setTotalInstrutores] = useState(0);

  const [search, setSearch] = useState("");
  const [orderby, setOrderby] = useState("");
  const [order, setOrder] = useState("");

  const limit = 20;
  const disableButton = !order || !orderby;

  async function getInstrutores() {
    try {
      const response = await lmsService.getInstrutoresList(
        {
          limit,
          page,
          order,
          orderby,
          search,
        },
        "no-cache"
      );

      setInstrutores(response.data.instrutor_collection.instrutores);
    } catch (err) {
      throw err;
    }
  }

  async function getCountInstrutores() {
    try {
      const response = await lmsService.getInstrutoresCount();

      setTotalInstrutores(response.data.instrutor_count.count);
    } catch (err) {
      throw err;
    }
  }

  async function handleSubmit() {
    try {
      setError("");
      setLoading(true);

      await getInstrutores();
      await getCountInstrutores();
    } catch (e) {
      setError("Erro :(");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    handleSubmit();
  }, [page]);

  async function deleteInstrutor(id) {
    await lmsService.deleteInstrutor(id);

    const instrutoresFilter = instrutores.filter(
      (instrutor) => instrutor.id !== id
    );

    setInstrutores(instrutoresFilter);
  }

  function handleDeleteInstrutor(id, nome) {
    swal({
      title: `Tem certeza que deseja excluir o instrutor "${nome}"?`,
      text: "Não será possível cadastrar novas turmas com esse instrutor",
      icon: "warning",
      buttons: ["Cancelar", "OK"],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        await deleteInstrutor(id);
        swal(`Instrutor "${nome}" excluído!`, {
          icon: "success",
        });
      }
    });
  }

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <div className="btn-top-container">
              <Link to="/Instrutor-new" className="btn btn-info btn-fill">
                Novo Instrutor
              </Link>
            </div>

            <div>
              <Row>
                <Col md={6} sm={12}>
                  <Row>
                    <Col md={4}>
                      <input
                        type="text"
                        name="search"
                        className="form-control"
                        placeholder="Pesquisar..."
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </Col>
                    <Col md={2}>
                      <Button
                        bsStyle="info"
                        fill
                        onClick={() => handleSubmit()}
                      >
                        Buscar
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col md={6} sm={12}>
                  <Row>
                    <Col md={4}>
                      <Select
                        name="orderby"
                        placeholder="Ordernar por"
                        onChange={({ value }) => setOrderby(value)}
                        options={[
                          {
                            value: "id",
                            label: "ID",
                          },
                          {
                            value: "nome",
                            label: "Nome",
                          },
                        ]}
                      />
                    </Col>
                    <Col md={4}>
                      <Select
                        name="order"
                        placeholder="Tipo de ordenação"
                        onChange={({ value }) => setOrder(value)}
                        options={[
                          {
                            value: "DESC",
                            label: "Decrescente",
                          },
                          {
                            value: "ASC",
                            label: "Crescente",
                          },
                        ]}
                      />
                    </Col>
                    <Col md={2}>
                      <Button
                        bsStyle="info"
                        fill
                        type="button"
                        disabled={disableButton}
                        onClick={() => handleSubmit()}
                      >
                        Ordenar
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>

            <Card
              title="Instrutores"
              name="Instrutores"
              ctTableFullWidth
              ctTableResponsive
              content={
                <Table striped hover>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Nome</th>
                      <th>CPF</th>
                      <th>Localização</th>
                      <th />
                    </tr>
                  </thead>

                  <tbody>
                    {error && (
                      <tr>
                        <td colSpan={11}>{error}</td>
                      </tr>
                    )}

                    {loading ? (
                      <tr>
                        <td colSpan={11}>Carregando...</td>
                      </tr>
                    ) : (
                      instrutores.map((item) => (
                        <tr key={item.id}>
                          <td>{item.id}</td>
                          <td>{item.nome}</td>
                          <td>{item.cpf}</td>
                          <td>{item.is_interno ? "Interno" : "Externo"}</td>
                          <td style={{ textAlign: "right" }}>
                            <Link
                              className="btn btn-info"
                              to={`/Instrutor-edit/${item.id}`}
                            >
                              Editar
                            </Link>
                            <button
                              className="btn btn-danger"
                              onClick={() =>
                                handleDeleteInstrutor(item.id, item.nome)
                              }
                              style={{ marginLeft: 16 }}
                            >
                              Excluir
                            </button>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              }
            />
          </Col>

          <Col sm={12}>
            <Paginator
              currentPage={page}
              pages={Math.ceil(totalInstrutores / limit)}
              onChangePage={(page) => setPage(page)}
              buttons={6}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}
