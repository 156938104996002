import React, { useEffect, useState } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { NavLink } from "react-router-dom";
import { Query } from "react-apollo";
import gql from "graphql-tag";

import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import HttpLinkServices from "../../services/HttpLinkServices";
import Button from "../../components/CustomButton/CustomButton.jsx";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Config from "../../config/config"; //
import Paginator from "../../components/Paginator";

const classroom_client = new ApolloClient({
  link: HttpLinkServices.lms,
  cache: new InMemoryCache(),
});

const account_client = new ApolloClient({
  link: HttpLinkServices.account,
  cache: new InMemoryCache(),
});

function getUrl($param, $param_1 = null, $param_2 = null) {
  var $url = "/#/" + $param;
  if ($param_1 != null) {
    $url += "/" + $param_1;
  }
  if ($param_2 != null) {
    $url += "/" + $param_2;
  }
  return $url;
}

const GET_CLASSROOM = gql`
  query getClassroom(
    $search: String!
    $limit: Int!
    $page: Int!
    $order: String!
    $orderby: String!
  ) {
    classroom_collections(
      search: $search
      limit: $limit
      page: $page
      order: $order
      orderby: $orderby
    ) {
      classroom {
        id
        title
        datetime_start_format
        userowner_id
        course {
          title
        }
      }
    }
  }
`;

const COUNT_SUBSCRIPTION_IS_PRESENT = gql`
  query countSubscriptionsIsPresent($classroom_id: Int!) {
    countSubscriptionsIsPresent(classroom_id: $classroom_id) {
      count
    }
  }
`;

const COUNT_SUBSCRIPTION_IS_NOT_PRESENT = gql`
  query countSubscriptionsIsNotPresent($classroom_id: Int!) {
    countSubscriptionsIsNotPresent(classroom_id: $classroom_id) {
      count
    }
  }
`;

const COUNT_SUBSCRIPTION_IS_NOT_INITIATED = gql`
  query countSubscriptionsIsNotInitiated($classroom_id: Int!) {
    countSubscriptionsIsNotInitiated(classroom_id: $classroom_id) {
      count
    }
  }
`;

const DELETE_CLASSROOM = gql`
  mutation deleteClassroom($id: Int!) {
    deleteClassroom(id: $id) {
      id
    }
  }
`;

export default function ClassroomList() {
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [orderby, setOrderby] = useState("id");
  const [order, setOrder] = useState("DESC");
  const [totalUsers, setTotalUsers] = useState(0);

  function toggleOrder(event) {
    setOrder(order == "DESC" ? "ASC" : "DESC");
  }

  function handleSubmit(event) {
    event.preventDefault();

    setSearch(event.target.elements.search.value);
    setOrder(event.target.elements.order.value);
    setOrderby(event.target.elements.orderby.value);
    setPage(1);
  }

  function countClassroom() {
    classroom_client
      .query({
        query: gql`
          query countClassroom($search: String!) {
            classroom_count(search: $search) {
              count
            }
          }
        `,
        variables: {
          search: search,
        },
      })
      .then((result) => setTotalUsers(result.data.classroom_count.count));
  }

  useEffect(() => {
    countClassroom();
  }, [search]);

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <div className="btn-top-container">
              <NavLink
                to={"/Classroom-new"}
                className="btn btn-info btn-fill"
                activeClassName=""
              >
                Nova Turma Presencial
              </NavLink>
            </div>

            <Card
              title={"Turmas Presenciais"}
              classroom="Gerenciar turmas presenciais"
              ctTableFullWidth
              ctTableResponsive
              content={
                <Table striped hover>
                  <thead>
                    <tr>
                      <th colSpan={9}>
                        <form onSubmit={handleSubmit}>
                          <Row>
                            <Col md={6} lg={3}>
                              <input
                                type="text"
                                name="search"
                                className="form-control"
                              />
                            </Col>
                            <Col md={2}>
                              <Button bsStyle="info" fill type="submit">
                                Buscar
                              </Button>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6} lg={1}>
                              <select name="orderby" className="form-control">
                                <option value="id">ID</option>
                              </select>
                            </Col>
                            <Col md={6} lg={2}>
                              <select name="order" className="form-control">
                                <option value="DESC">Decrescente</option>
                                <option value="ASC" selected={order == "ASC"}>
                                  Crescente
                                </option>
                              </select>
                            </Col>
                            <Col md={2}>
                              <Button bsStyle="info" fill type="submit">
                                Ordenar
                              </Button>
                            </Col>
                          </Row>
                        </form>
                      </th>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <th width="80">
                        <a onClick={toggleOrder}>
                          <i
                            class={
                              "fas fas " +
                              (order == "DESC"
                                ? "fa-angle-up"
                                : "fa-angle-down")
                            }
                          ></i>
                        </a>
                        ID
                      </th>
                      <th>Curso</th>
                      <th>Lista realizada</th>
                      <th>Totais</th>
                      <th>Segmentação</th>
                      <th>Data / Hora</th>
                      <th />
                      <th />
                      <th />
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <Query
                      client={classroom_client}
                      query={GET_CLASSROOM}
                      variables={{
                        search,
                        limit,
                        page,
                        order,
                        orderby,
                      }}
                      fetchPolicy="no-cache"
                    >
                      {({ loading, error, data }) => {
                        if (loading)
                          return (
                            <tr>
                              <td>Carregando...</td>
                            </tr>
                          );
                        if (error)
                          return (
                            <tr>
                              <td>Erro :(</td>
                            </tr>
                          );

                        if (!data.classroom_collections.classroom) {
                          return (
                            <tr>
                              <td>(Vazio)</td>
                            </tr>
                          );
                        }

                        return data.classroom_collections.classroom.map(
                          (item) => (
                            <tr key={item.id}>
                              <td>{item.id}</td>
                              <td>{item.course.title}</td>
                              <td>
                                <Query
                                  client={classroom_client}
                                  variables={{ classroom_id: item.id }}
                                  query={gql`
                                    query is_attendance_list(
                                      $classroom_id: Int!
                                    ) {
                                      is_attendance_list(
                                        classroom_id: $classroom_id
                                      ) {
                                        is_attendance_list
                                      }
                                    }
                                  `}
                                >
                                  {({ loading, error, data }) => {
                                    if (loading)
                                      return <span>Carregando...</span>;
                                    if (error) return <span>Erro :(</span>;
                                    return (
                                      <span>
                                        {data.is_attendance_list
                                          .is_attendance_list == 1 ? (
                                          <span>Sim</span>
                                        ) : (
                                          <strong>Não</strong>
                                        )}
                                      </span>
                                    );
                                  }}
                                </Query>
                              </td>
                              <td>
                                <Query
                                  client={classroom_client}
                                  query={COUNT_SUBSCRIPTION_IS_PRESENT}
                                  variables={{
                                    classroom_id: item.id,
                                  }}
                                  fetchPolicy="no-cache"
                                >
                                  {({ loading, error, data }) => {
                                    if (loading)
                                      return (
                                        <tr>
                                          <td>Carregando...</td>
                                        </tr>
                                      );
                                    if (error)
                                      return (
                                        <tr>
                                          <td>Erro :(</td>
                                        </tr>
                                      );

                                    return (
                                      <div key={1}>
                                        Concluídos:
                                        {data.countSubscriptionsIsPresent.count}
                                      </div>
                                    );
                                  }}
                                </Query>

                                <Query
                                  client={classroom_client}
                                  query={COUNT_SUBSCRIPTION_IS_NOT_PRESENT}
                                  variables={{
                                    classroom_id: item.id,
                                  }}
                                  fetchPolicy="no-cache"
                                >
                                  {({ loading, error, data }) => {
                                    if (loading)
                                      return (
                                        <tr>
                                          <td>Carregando...</td>
                                        </tr>
                                      );
                                    if (error)
                                      return (
                                        <tr>
                                          <td>Erro :(</td>
                                        </tr>
                                      );

                                    return (
                                      <div key={1}>
                                        Ausentes:
                                        {
                                          data.countSubscriptionsIsNotPresent
                                            .count
                                        }
                                      </div>
                                    );
                                  }}
                                </Query>

                                <Query
                                  client={classroom_client}
                                  query={COUNT_SUBSCRIPTION_IS_NOT_INITIATED}
                                  variables={{
                                    classroom_id: item.id,
                                  }}
                                  fetchPolicy="no-cache"
                                >
                                  {({ loading, error, data }) => {
                                    if (loading)
                                      return (
                                        <tr>
                                          <td>Carregando...</td>
                                        </tr>
                                      );
                                    if (error)
                                      return (
                                        <tr>
                                          <td>Erro :(</td>
                                        </tr>
                                      );

                                    return (
                                      <div key={1}>
                                        Não iniciados:
                                        {
                                          data.countSubscriptionsIsNotInitiated
                                            .count
                                        }
                                      </div>
                                    );
                                  }}
                                </Query>
                              </td>
                              <td>
                                <Query
                                  client={account_client}
                                  variables={{
                                    account_field_id: 10,
                                    module_name: "Classroom",
                                    item_id: item.id,
                                  }}
                                  query={gql`
                                    query getItens(
                                      $account_field_id: Int!
                                      $module_name: String!
                                      $item_id: Int!
                                    ) {
                                      get_segmentations(
                                        account_field_id: $account_field_id
                                        module_name: $module_name
                                        item_id: $item_id
                                      ) {
                                        segmentations {
                                          account_field_value {
                                            value
                                          }
                                        }
                                      }
                                    }
                                  `}
                                >
                                  {({ loading, error, data }) => {
                                    if (loading)
                                      return (
                                        <tr>
                                          <td>Carregando...</td>
                                        </tr>
                                      );
                                    if (error)
                                      return (
                                        <tr>
                                          <td>Erro :(</td>
                                        </tr>
                                      );

                                    return data.get_segmentations.segmentations.map(
                                      (account_field_value) => (
                                        <div>
                                          {
                                            account_field_value
                                              .account_field_value.value
                                          }
                                        </div>
                                      )
                                    );
                                  }}
                                </Query>
                                <Query
                                  client={account_client}
                                  variables={{
                                    account_field_id: 9,
                                    module_name: "Classroom",
                                    item_id: item.id,
                                  }}
                                  query={gql`
                                    query getItens(
                                      $account_field_id: Int!
                                      $module_name: String!
                                      $item_id: Int!
                                    ) {
                                      get_segmentations(
                                        account_field_id: $account_field_id
                                        module_name: $module_name
                                        item_id: $item_id
                                      ) {
                                        segmentations {
                                          account_field_value {
                                            value
                                          }
                                        }
                                      }
                                    }
                                  `}
                                >
                                  {({ loading, error, data }) => {
                                    if (loading)
                                      return (
                                        <tr>
                                          <td>Carregando...</td>
                                        </tr>
                                      );
                                    if (error)
                                      return (
                                        <tr>
                                          <td>Erro :(</td>
                                        </tr>
                                      );

                                    return data.get_segmentations.segmentations.map(
                                      (account_field_value) => (
                                        <div>
                                          {
                                            account_field_value
                                              .account_field_value.value
                                          }
                                        </div>
                                      )
                                    );
                                  }}
                                </Query>
                              </td>
                              <td>
                                {new Date(
                                  item.datetime_start_format
                                ).toLocaleDateString("pt-BR")}{" "}
                                <br />
                                Início:{" "}
                                {new Date(
                                  item.datetime_start_format
                                ).getHours()}
                                h{" "}
                                {new Date(
                                  item.datetime_start_format
                                ).getMinutes()}
                                min
                              </td>

                              <td>
                                <a
                                  className="btn btn-info"
                                  href={
                                    Config.baseUrl +
                                    "/api/v1/lms/subscription_export?classroom_id=" +
                                    item.id
                                  }
                                >
                                  Baixar lista
                                </a>
                              </td>
                              <td>
                                <a
                                  className="btn btn-info"
                                  href={getUrl(
                                    "Classroom-subscription",
                                    item.id
                                  )}
                                >
                                  Lista de presença
                                </a>
                              </td>
                              <td>
                                <a
                                  className="btn btn-info"
                                  href={getUrl("classroom-edit", item.id)}
                                >
                                  Editar
                                </a>
                              </td>
                              <td>
                                <a
                                  className="btn btn-success"
                                  href={getUrl(
                                    "segmentation",
                                    "Classroom",
                                    item.id
                                  )}
                                >
                                  Segmentar
                                </a>
                              </td>
                              <td>
                                <Button
                                  bsStyle="danger"
                                  onClick={() => {
                                    confirmAlert({
                                      title: "",
                                      message:
                                        "Tem certeza que deseja excluir " +
                                        item.title,
                                      buttons: [
                                        {
                                          label: "Sim",
                                          onClick: () => {
                                            // Delete item
                                            classroom_client
                                              .mutate({
                                                mutation: DELETE_CLASSROOM,
                                                variables: {
                                                  id: item.id,
                                                },
                                              })
                                              .then((result) => {
                                                window.location.reload();
                                              });
                                          },
                                        },
                                        {
                                          label: "Não",
                                        },
                                      ],
                                    });
                                  }}
                                >
                                  Excluir
                                </Button>
                              </td>
                            </tr>
                          )
                        );
                      }}
                    </Query>
                  </tbody>
                </Table>
              }
            />
          </Col>
          <Col sm={12}>
            <Paginator
              currentPage={page}
              pages={Math.ceil(totalUsers / limit)}
              onChangePage={(page) => setPage(page)}
              buttons={6}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}
