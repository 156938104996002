import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { NavLink } from "react-router-dom";
import { Query } from "react-apollo";
import gql from "graphql-tag";

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import HttpLinkServices from "../../services/HttpLinkServices";
import Button from "../../components/CustomButton/CustomButton.jsx";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; //

const answer_client = new ApolloClient({
  link: HttpLinkServices.lms,
  cache: new InMemoryCache(),
});

function getUrl($param, $param_1 = null,$param_2 = null) {
  var $url = "/#/"+$param;
  if($param_1 != null) {
    $url += "/"+$param_1;
  }
  if($param_2 != null) {
    $url += "/"+$param_2;
  }
  return $url;
}

const GET_ANSWERS = gql`query getAnswers($question_id: Int!){
    answer_collections(question_id: $question_id){
      answers{
        id,title, is_correct
      }
    }
  }
`;

const DELETE_ANSWER = gql`
  mutation deleteAnswer($id: Int!){
    deleteAnswer(id:$id) {
      id
    }
  }
`;

class AnswerList extends Component {


  render() {
    
    return (

      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <div className="btn-top-container">
                <NavLink
                    to={"/Question/"+this.props.match.params.type_id+"/"+this.props.match.params.question_id}
                    className="btn btn-default btn-fill"
                    activeClassName=""
                  >
                  VOLTAR
                </NavLink>
                <NavLink
                    to={'/Answer-new/'+this.props.match.params.type_id+"/"+this.props.match.params.question_id+"/"+this.props.match.params.question_id}
                    className="btn btn-info btn-fill"
                    activeClassName=""
                  >
                  Nova Resposta
                </NavLink>
              </div>

              <Card
                title={"Respostas"}
                answer="Gerenciar perguntas"
                ctTableFullWidth
                ctTableResponsive
                content={
                  
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Título</th>
                        <th>Correta</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>

                        <Query
                          variables={{question_id: this.props.match.params.question_id}}
                          client={answer_client}
                          query={GET_ANSWERS}
                          fetchPolicy='no-cache'
                        >
                          {({ loading, error, data }) => {
                            if (loading) return <tr><td>Carregando...</td></tr>;
                            if (error) return <tr><td>Erro :(</td></tr>;
                              
                              if(!data.answer_collections) {
                                return (<tr><td>(Vazio)</td></tr>);
                              } 

                            return data.answer_collections.answers.map((item) => (
                              
                              <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.title}</td>
                                <td>{item.is_correct ? "Sim" : "Não"}</td>
                                <td>
                                  <a className="btn btn-info" href={getUrl('Answer-edit/'+this.props.match.params.type_id+"/"+this.props.match.params.question_id+"/"+this.props.match.params.question_id,item.id)}>Editar</a>
                                </td>
                                <td>
                                <Button bsStyle="danger" onClick={() => {
                                    confirmAlert({
                                      title: '',
                                      message: "Tem certeza que deseja excluir "+item.title,
                                      buttons: [
                                        {
                                          label: 'Sim',
                                          onClick: () => {
                                            // Delete item
                                            answer_client.mutate({
                                              mutation: DELETE_ANSWER,
                                              variables: {
                                                id:item.id
                                              }
                                            }).then(
                                              result => {
                                                window.location.reload();
                                            });
                                          }
                                        },
                                        {
                                          label: 'Não'
                                        }
                                      ]
                                    });
                                    
                                  }}>
                                  Excluir
                                </Button>
                                </td>
                              </tr>
                            ));
                          }}
                        </Query>
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default AnswerList;
