import React, { Component } from "react";
import { Grid, Row, Col, FormGroup, ControlLabel } from "react-bootstrap";

import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import gql from "graphql-tag";
import HttpLinkServices from "../../services/HttpLinkServices";
import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import Select from "react-select";
import DateTimePicker from "react-datetime-picker";
import Config from "../../config/config";

const lms_client = new ApolloClient({
  link: HttpLinkServices.lms,
  cache: new InMemoryCache(),
});

const dam_client = new ApolloClient({
  link: HttpLinkServices.dam,
  cache: new InMemoryCache(),
});

const ADD_TODO = gql`
  mutation createOrUpdateTrail(
    $id: Int!
    $title: String!
    $reactionevaluation_id: Int!
    $proof_id: Int!
    $active: Int!
    $blockcontent: Int!
    $date_publish: String!
    $date_unpublish: String!
  ) {
    createOrUpdateTrail(
      id: $id
      input: {
        title: $title
        reactionevaluation_id: $reactionevaluation_id
        active: $active
        proof_id: $proof_id
        blockcontent: $blockcontent
        date_publish: $date_publish
        date_unpublish: $date_unpublish
      }
    ) {
      id
    }
  }
`;

const GET_TRAIL = gql`
  query getTrail($trail_id: Int!) {
    trail(id: $trail_id) {
      id
      title
      thumbnail
      active
      blockcontent
      date_publish_format
      date_unpublish_format
      reactionevaluation {
        value: id
        label: title
      }
      proof {
        value: id
        label: title
      }
    }
  }
`;

const GET_PROOFS = gql`
  query getProofs($type_id: Int!) {
    proof_collections(type_id: $type_id) {
      proofs {
        value: id
        label: title
      }
    }
  }
`;

const GET_CATEGORY_HAS_TRAIL = gql`
  query categoryHasTrail_collection($trail_id: Int!) {
    categoryHasTrail_collection(trail_id: $trail_id) {
      category_has_trail {
        id
        category_id
      }
    }
  }
`;

const CREATE_CATEGORY_HAS_TRAIL = gql`
  mutation createCategoryHasTrail($trail_id: Int!, $category_id: Int!) {
    createCategoryHasTrail(trail_id: $trail_id, category_id: $category_id) {
      id
    }
  }
`;

const DELETE_CATEGORY_HAS_TRAIL = gql`
  mutation deleteCategoryHasTrail($trail_id: Int!, $category_id: Int!) {
    deleteCategoryHasTrail(trail_id: $trail_id, category_id: $category_id) {
      id
    }
  }
`;

const GET_CATEGORIES = gql`
  {
    category_collections {
      categories {
        value: id
        label: title
      }
    }
  }
`;

class TrailItem extends Component {
  trail_id;
  trail_edit;

  constructor() {
    super();
    this.state = {
      alertInfo: "",
      datePublish: new Date(),
      dateUnpublish: null,
    };

    this.start_publish_date = true;
    this.start_unpublish_date = true;
    this.start_active = true;
    this.start_blockcontent = true;
  }

  onChange_datePublish = (datePublish) => this.setState({ datePublish });
  onChange_dateUnpublish = (dateUnpublish) => this.setState({ dateUnpublish });

  handleCheckboxChange = (e) => {
    this.setState((state) => ({
      ...state,
      checkboxValue_active: !this.state.checkboxValue_active,
    }));
  };

  handleCheckboxChange_blockcontent = (e) => {
    this.setState((state) => ({
      ...state,
      checkboxValue_blockcontent: !this.state.checkboxValue_blockcontent,
    }));
  };

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({
      alertInfo: "Salvo com sucesso!",
    });

    setTimeout(() => {
      this.setState({
        alertInfo: "",
      });
    }, 3000);
  };

  onChange = (inputValue, action, item_id) => {
    // Adicionar
    if (action.action === "select-option") {
      lms_client.mutate({
        mutation: CREATE_CATEGORY_HAS_TRAIL,
        variables: {
          trail_id: item_id,
          category_id: action.option.value,
        },
      });
    }
    // Remover
    else if (action.action === "remove-value") {
      lms_client.mutate({
        mutation: DELETE_CATEGORY_HAS_TRAIL,
        variables: {
          trail_id: item_id,
          category_id: action.removedValue.value,
        },
      });
    }
  };

  render() {
    if (this.props.match.params.id) {
      this.trail_id = this.props.match.params.id;
    } else {
      this.trail_id = 0;
    }

    return (
      <Query
        client={lms_client}
        query={GET_TRAIL}
        variables={{ trail_id: this.trail_id }}
      >
        {({ loading, error, data }) => {
          if (loading) return <div>Carregando...</div>;
          if (error) return <div>Erro :(</div>;

          this.trail_edit = data.trail;

          if (
            this.start_publish_date &&
            this.trail_edit &&
            this.trail_edit.date_publish_format
          ) {
            this.start_publish_date = false;
            this.setState({
              datePublish: new Date(this.trail_edit.date_publish_format),
            });
          }
          if (
            this.start_unpublish_date &&
            this.trail_edit &&
            this.trail_edit.date_unpublish_format
          ) {
            this.start_unpublish_date = false;
            this.setState({
              dateUnpublish: new Date(this.trail_edit.date_unpublish_format),
            });
          }
          if (this.start_active && this.trail_edit && this.trail_edit.active) {
            this.start_active = false;
            this.setState({
              checkboxValue_active: this.trail_edit.active === 1 ? true : false,
            });
          }
          if (
            this.start_blockcontent &&
            this.trail_edit &&
            this.trail_edit.blockcontent
          ) {
            this.start_blockcontent = false;
            this.setState({
              checkboxValue_blockcontent:
                this.trail_edit.blockcontent === 1 ? true : false,
            });
          }

          return (
            <Mutation mutation={ADD_TODO} client={lms_client}>
              {(createOrUpdateTrail, { data }) => (
                <div className="content">
                  <Grid fluid>
                    <Row>
                      <Col md={12}>
                        <div className="btn-top-container">
                          <NavLink
                            to="/Trail"
                            className="btn btn-info btn-fill"
                            activeClassName=""
                          >
                            VOLTAR
                          </NavLink>
                          <NotificationSystem
                            ref="notificationSystem"
                            style={style}
                          />
                        </div>

                        <Card
                          title="Trilha"
                          content={
                            <form
                              onSubmit={(e) => {
                                this.handleSubmit(e);
                                e.preventDefault();
                                createOrUpdateTrail({
                                  variables: {
                                    id: parseInt(this.trail_id),
                                    title: e.target.elements.title.value,
                                    reactionevaluation_id: e.target.elements
                                      .reactionevaluation_id.value
                                      ? parseInt(
                                          e.target.elements
                                            .reactionevaluation_id.value
                                        )
                                      : 0,
                                    proof_id: e.target.elements.proof_id.value
                                      ? parseInt(
                                          e.target.elements.proof_id.value
                                        )
                                      : 0,
                                    active: e.target.elements.active.checked
                                      ? 1
                                      : 0,
                                    blockcontent: e.target.elements.blockcontent
                                      .checked
                                      ? 1
                                      : 0,
                                    date_publish:
                                      e.target.elements.date_publish.value,
                                    date_unpublish:
                                      e.target.elements.date_unpublish.value,
                                  },
                                });
                              }}
                            >
                              <FormInputs
                                ncols={["col-md-12"]}
                                proprieties={[
                                  {
                                    name: "title",
                                    label: "Título",
                                    type: "text",
                                    bsClass: "form-control",
                                    placeholder: "Título",
                                    defaultValue: this.trail_edit
                                      ? this.trail_edit.title
                                      : "",
                                  },
                                ]}
                              />

                              <FormGroup>
                                <ControlLabel>Categorias</ControlLabel>
                                {!this.trail_edit || !this.trail_edit.id ? (
                                  <div>
                                    <strong>
                                      (Salve a trilha, e edite novamente para
                                      definir as categorias)
                                    </strong>
                                  </div>
                                ) : (
                                  <div>
                                    <Query
                                      client={dam_client}
                                      query={GET_CATEGORIES}
                                    >
                                      {({ loading, error, data }) => {
                                        if (loading)
                                          return (
                                            <tr>
                                              <td>Carregando...</td>
                                            </tr>
                                          );
                                        if (error)
                                          return (
                                            <tr>
                                              <td>Erro :(</td>
                                            </tr>
                                          );

                                        var categories =
                                          data.category_collections.categories;

                                        return (
                                          <Query
                                            client={lms_client}
                                            query={GET_CATEGORY_HAS_TRAIL}
                                            variables={{
                                              trail_id: this.trail_edit.id,
                                            }}
                                            fetchPolicy="no-cache"
                                          >
                                            {({ loading, error, data }) => {
                                              if (loading)
                                                return <div>Carregando...</div>;
                                              if (error)
                                                return <div>Erro :(</div>;

                                              var categories_has_trail_collection_default = [];
                                              var categories_has_trail_collection =
                                                data.categoryHasTrail_collection
                                                  .category_has_trail;
                                              categories.map(
                                                (category_item) => {
                                                  categories_has_trail_collection.map(
                                                    (
                                                      categories_has_trail_item
                                                    ) => {
                                                      if (
                                                        category_item.value ===
                                                        categories_has_trail_item.category_id
                                                      ) {
                                                        categories_has_trail_collection_default.push(
                                                          category_item
                                                        );
                                                      }
                                                    }
                                                  );
                                                }
                                              );

                                              return (
                                                <Select
                                                  defaultValue={
                                                    categories_has_trail_collection_default
                                                  }
                                                  isMulti
                                                  isSearchable
                                                  name={"categories"}
                                                  options={categories}
                                                  className="basic-multi-select"
                                                  classNamePrefix="select"
                                                  //inputValue={inputValue}
                                                  onChange={(
                                                    inputValue,
                                                    action
                                                  ) =>
                                                    this.onChange(
                                                      inputValue,
                                                      action,
                                                      this.trail_edit.id
                                                    )
                                                  }
                                                  //onInputChange={this.onInputChange}
                                                  //onFocus={() => this.setState({currentInputChange: item.id})}
                                                  isClearable={true}
                                                  //onInputChange={this.onInputChange}
                                                />
                                              );
                                            }}
                                          </Query>
                                        );
                                      }}
                                    </Query>
                                  </div>
                                )}
                              </FormGroup>

                              <hr />

                              <FormGroup>
                                <ControlLabel>Avaliação de reação</ControlLabel>
                                <div>
                                  <Query
                                    variables={{ type_id: 2 }} // 2 é o ID da avaliçaão de reação no microseviço de avalações
                                    client={lms_client}
                                    query={GET_PROOFS}
                                  >
                                    {({ loading, error, data }) => {
                                      if (loading)
                                        return <div>Carregando...</div>;
                                      if (error) return <div>Erro :(</div>;
                                      var proofs = data;
                                      return (
                                        <Select
                                          name="reactionevaluation_id"
                                          options={
                                            proofs.proof_collections
                                              ? proofs.proof_collections.proofs
                                              : []
                                          }
                                          className="basic-select"
                                          classNamePrefix="select"
                                          isClearable={true}
                                          defaultValue={
                                            this.trail_edit &&
                                            this.trail_edit.reactionevaluation
                                              ? this.trail_edit
                                                  .reactionevaluation
                                              : ""
                                          }
                                        />
                                      );
                                    }}
                                  </Query>
                                </div>
                              </FormGroup>

                              <FormGroup>
                                <ControlLabel>Prova</ControlLabel>
                                <div>
                                  <Query
                                    variables={{ type_id: 1 }} // 1 é o ID da prova no microseviço de avalações
                                    client={lms_client}
                                    query={GET_PROOFS}
                                  >
                                    {({ loading, error, data }) => {
                                      if (loading)
                                        return <div>Carregando...</div>;
                                      if (error) return <div>Erro :(</div>;
                                      var proofs = data;
                                      return (
                                        <Select
                                          name="proof_id"
                                          options={
                                            proofs.proof_collections
                                              ? proofs.proof_collections.proofs
                                              : []
                                          }
                                          className="basic-select"
                                          classNamePrefix="select"
                                          isClearable={true}
                                          defaultValue={
                                            this.trail_edit &&
                                            this.trail_edit.proof
                                              ? this.trail_edit.proof
                                              : ""
                                          }
                                        />
                                      );
                                    }}
                                  </Query>
                                </div>
                              </FormGroup>

                              <FormGroup>
                                <ControlLabel>
                                  <input
                                    value="1"
                                    name="blockcontent"
                                    type="checkbox"
                                    checked={
                                      this.state.checkboxValue_blockcontent
                                    }
                                    onChange={
                                      this.handleCheckboxChange_blockcontent
                                    }
                                  />{" "}
                                  Bloquear conteúdos
                                </ControlLabel>
                              </FormGroup>

                              <hr />
                              <FormGroup>
                                <ControlLabel>
                                  <input
                                    value="1"
                                    name="active"
                                    type="checkbox"
                                    checked={this.state.checkboxValue_active}
                                    onChange={this.handleCheckboxChange}
                                  />{" "}
                                  Ativo
                                </ControlLabel>
                              </FormGroup>

                              <FormGroup>
                                <ControlLabel>Data de publicação</ControlLabel>
                                <div>
                                  <DateTimePicker
                                    name="date_publish"
                                    onChange={this.onChange_datePublish}
                                    value={this.state.datePublish}
                                    required={true}
                                    format="d/M/y H:m"
                                    lang="pt"
                                  />
                                </div>
                              </FormGroup>

                              <FormGroup>
                                <ControlLabel>
                                  Data para despublicar
                                </ControlLabel>
                                <div>
                                  <DateTimePicker
                                    name="date_unpublish"
                                    onChange={this.onChange_dateUnpublish}
                                    value={this.state.dateUnpublish}
                                    required={false}
                                    format="d/M/y H:m"
                                    lang="pt"
                                  />
                                </div>
                              </FormGroup>

                              <Row>
                                <Col md={10}>
                                  <AlertInfo
                                    ref="alertSave"
                                    alertInfo={this.state.alertInfo}
                                  />
                                </Col>
                                <Col md={2}>
                                  <Button
                                    bsStyle="info"
                                    pullRight
                                    fill
                                    type="submit"
                                  >
                                    Salvar
                                  </Button>
                                </Col>
                              </Row>
                              <div className="clearfix" />
                            </form>
                          }
                        />

                        <Card
                          title={this.trail_edit ? "Thumbnail" : ""}
                          content={
                            this.trail_edit ? (
                              <form
                                action={Config.baseUrl + "/api/v1/lms/trail_thumb"} method="post"
                                enctype="multipart/form-data"
                              >
                                <input
                                  type="hidden"
                                  name="returnUrl"
                                  value={"/#/Trail-edit/" + this.trail_edit.id}
                                />
                                <input
                                  type="hidden"
                                  name="id"
                                  value={this.trail_edit.id}
                                />

                                <input type="file" name="file" required />

                                <Row>
                                  <Col md={2}>
                                    <Button
                                      bsStyle="info"
                                      pullRight
                                      fill
                                      type="submit"
                                    >
                                      Upload
                                    </Button>
                                  </Col>
                                </Row>
                                <div className="clearfix" />
                              </form>
                            ) : (
                              <div></div>
                            )
                          }
                        />

                        <Card
                          title=""
                          content={
                            this.trail_edit && this.trail_edit.thumbnail ? (
                              <div>
                                <img
                                  alt="thumb"
                                  src={
                                    Config.baseUrl + "/lms/assets/" + this.trail_edit.thumbnail
                                  }
                                  height="100px"
                                />
                              </div>
                            ) : (
                              <div/>
                            )
                          }
                        />
                      </Col>
                    </Row>
                  </Grid>
                </div>
              )}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}

export default TrailItem;
