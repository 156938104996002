  // considera como válidos os caracteres presentes no regex
  // pattern é uma string do tipo '(00) 0000-0000' ou '000.000.000-00'
  export const inputMask = (event, pattern) => {
    let masked = event.target.value
    const regex = /[a-zA-Z]|[0-9]/ 
    
    if (event.keyCode !== 8) {
      const spreadInput = [...event.target.value]
      spreadInput.forEach((char, index) => {
        if (!regex.test(pattern[index]) && char !== pattern[index]) {
          masked = masked.substring(0, index) + pattern[index] + masked.substring(index)
        }
      })
    }

    event.target.value = masked
  }

  // primeira maiuscula (teste => Teste)
  export const capitalizeString = string =>
    string.charAt(0).toUpperCase() + string.slice(1)