import ApiServices from "./ApiServices";
import gql from "graphql-tag";

export default class Parceiro extends ApiServices {
  constructor(credential = 'include') {
    super('/api/v1/parceiro/', credential);
  }

  async getBranchs(variables = {}, fetchPolicy = "") {
    return await this.apolloClass.query({
      query: gql`
        query getBranchs(
          $search: String!,
          $limit: Int!,
          $page: Int!,
          $order: String!,
          $orderby: String!
        ) {
          branch_collections(
            search: $search,
            page: $page,
            limit: $limit,
            order: $order,
            orderby: $orderby
          ) {
          branch {
            id,
            value
          }
        }
      }`,
      variables,
      fetchPolicy,
    });
  }

  async getBranch(variables = {}) {
    return await this.apolloClass.query({
      query: gql`
        query getBranch(
          $id: Int!,
        ) {
          branch(
            id: $id,
          ) {
            id,
            value
          }
        }
      `,
      variables,
    });
  }

  async createBranch(variables = {}) {
    return await this.apolloClass.mutate({
      mutation: gql`
        mutation createOrUpdateBranch($name: String!) {
          createOrUpdateBranch(input: { value: $name }) {
            id,
            value
          }
        }
      `,
      variables,
    });
  }

  async updateBranch(variables = {}) {
    return await this.apolloClass.mutate({
      mutation: gql`
        mutation createOrUpdateBranch($id: Int!, $name: String!, $showSignUpPage: Boolean!) {
          createOrUpdateBranch(id: $id, input: { value: $name, showSignUpPage: $showSignUpPage }) {
            id,
            value,
            showSignUpPage
          }
        }
      `,
      variables,
    });
  }

  async deleteBranch(id) {
    return await this.apolloClass.mutate({
      mutation: gql`
        mutation deleteBranch($id: Int!) {
          deleteBranch(id: $id) {
              id
          }
        }
      `,
      variables: {
        id,
      }
    })
  }

  async getBranchsCount(variables = {}) {
    return await this.apolloClass.query({
      query: gql`
        query getBranchsCount(
          $search: String!,
        ) {
          branch_count(
            search: $search,
          ) {
          count
        }
      }`,
      variables,
    });
  }
}