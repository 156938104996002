import React, { useState, useEffect } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Button from "../../components/CustomButton/CustomButton.jsx";
import HttpLinkServices from "../../services/HttpLinkServices";
import Paginator from "../../components/Paginator";

const cadastro_client = new ApolloClient({
  link: HttpLinkServices.parceiro,
  cache: new InMemoryCache(),
});

function getUrl($param, $param_1 = null,$param_2 = null) {
  var $url = "/#/" + $param;
  if ($param_1 != null) {
    $url += "/" + $param_1;
  }
  if ($param_2 != null) {
    $url += "/" + $param_2;
  }
  return $url;
}

const GET_CADASTROS = gql`
  query getCadastros(
    $search: String!
    $limit: Int!
    $page: Int!
    $order: String!
    $orderby: String!
  ) {
    cadastros_collections(
      search: $search
      limit: $limit
      page: $page
      order: $order
      orderby: $orderby
    ) {
      cadastros {
       id, 
       name, 
       cpf, 
       email, 
       company, 
       role, 
       phone,
        
         branch{
            value
            }
      }
    }
  }
`;

const COUNT_CADASTROS = gql`query countCadastros($search: String!){
  cadastros_count(search:$search){
    count
  }
}

`;

export default function CadastroList() {
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [orderby, setOrderby] = useState("id");
  const [order, setOrder] = useState("DESC");
  const [totalUsers, setTotalUsers] = useState(0);

  function handleSubmit(event) {
    event.preventDefault();
    setSearch(event.target.elements.search.value);
    setOrder(event.target.elements.order.value);
    setOrderby(event.target.elements.orderby.value);
    setPage(1);
  }

  function countCadastros() {
    cadastro_client
      .query({
        query: gql`
          query countCadastros($search: String!) {
            cadastros_count(search: $search) {
              count
            }
          }
        `,
        variables: {
          search: search,
        },
      })
      .then((result) => setTotalUsers(result.data.cadastros_count.count));
  }

  useEffect(() => {
    countCadastros();
  }, [search]);

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              name={"Usuários"}
              cadastro="Gerenciar usuários"
              ctTableFullWidth
              ctTableResponsive
              content={
                <Table striped hover>
                  <thead>
                    <tr>
                      <th colSpan={9}>
                        <form onSubmit={handleSubmit}>
                          <Row>
                            <Col md={6} lg={3}>
                              <input
                                type="text"
                                name="search"
                                className="form-control"
                              />
                            </Col>
                            <Col md={2}>
                              <Button bsStyle="info" fill type="submit" >
                                Buscar
                              </Button>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6} lg={1}>
                              <select name="orderby" className="form-control">
                                <option value="id">ID</option>
                                <option value="name" selected={orderby == "name"}>Nome</option>
                              </select>
                            </Col>
                            <Col md={6} lg={2}>
                                <select name="order" className="form-control">
                                  <option value="DESC">Decrescente</option>
                                  <option value="ASC" selected={order == "ASC"}>Crescente</option>
                                </select>
                            </Col>
                            <Col md={2}>
                              <Button bsStyle="info" fill type="submit" >
                                Ordenar
                              </Button>
                            </Col>
                            </Row>
                          </form>
                      </th>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Nome</th>
                      <th>E-mail</th>
                      <th>Telefone</th>
                      <th>CPF</th>
                      <th>Empresa</th>
                      <th>Cargo</th>
                      <th>Ramo de atuação</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>

                      <Query
                        client={cadastro_client}
                        query={GET_CADASTROS}
                        variables={{
                          search, 
                          limit, 
                          page, 
                          order, 
                          orderby
                        }}
                        fetchPolicy='no-cache'
                      >
                        {({ loading, error, data }) => {
                          if (loading) return <tr><td>Carregando...</td></tr>;
                          if (error) {
                            return <tr><td>Erro :(</td></tr>;
                          };
                            
                            if(!data.cadastros_collections) {
                              return (<tr><td>(Vazio)</td></tr>);
                            } 

                          return data.cadastros_collections.cadastros.map((item) => (
                            
                            <tr key={item.id}>
                              <td>{item.id}</td>
                              <td>{item.name}</td>
                              <td>{item.email}</td>
                              <td>{item.phone}</td>
                              <td>{item.cpf}</td>
                              <td>{item.company}</td>
                              <td>{item.role}</td>
                              <td>{item.branch ? item.branch.value : ""}</td>
                              <td>
                                <a className="btn btn-info" href={getUrl('Parceiro-edit',item.id)}>Editar</a>
                            </td>
                          </tr>
                        ));
                      }}
                    </Query>
                  </tbody>
                </Table>
              }
            />
          </Col>
          <Col sm={12}>
            <Paginator
              currentPage={page}
              pages={Math.ceil(totalUsers / limit)}
              onChangePage={(page) => setPage(page)}
              buttons={6}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}
