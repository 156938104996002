import React, { useState, useEffect } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { NavLink } from "react-router-dom";
import { Query } from "react-apollo";
import gql from "graphql-tag";

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import Button from "../../components/CustomButton/CustomButton.jsx";
import Paginator from "../../components/Paginator";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import HttpLinkServices from "../../services/HttpLinkServices";
import Config from "../../config/config"; //

const dam_client = new ApolloClient({
  link: HttpLinkServices.dam,
  cache: new InMemoryCache(),
});

function getUrl($param, $param_1 = null, $param_2 = null) {
  var $url = "/#/" + $param;
  if ($param_1 != null) {
    $url += "/" + $param_1;
  }
  if ($param_2 != null) {
    $url += "/" + $param_2;
  }
  return $url;
}


const GET_DAM = gql`
  query getDam(
    $module_name: String!
    $external_parent_id: Int!
    $search: String!
    $limit: Int!
    $page: Int!
    $order: String!
    $orderby: String!
    ){
    assets_collections(
      module_name:$module_name
      external_parent_id:$external_parent_id
      search: $search
      limit: $limit
      page: $page
      order: $order
      orderby: $orderby
      ) {
      assets{
        id, title
      }
    }
  }
`;

const DELETE_DAM = gql`
  mutation deleteDam($id: Int!){
    deleteAsset(id:$id) {
      id
    }
  }
`;




const DamList = (props) => {
  const [search, setSearch] = useState("");
  const limit = 20;
  const [page, setPage] = useState(1);
  const [orderby, setOrderby] = useState("u.id");
  const [order, setOrder] = useState("DESC");
  const [totalDams, setTotalDams] = useState(0);

  function countAssets() {
    dam_client
      .query({
        query: gql`
          query AssetsCollectionsCount(
            $search: String!
            $module_name: String!
            $external_parent_id: Int!
            ) {
            assets_collections_count(
              search: $search
              module_name: $module_name
              external_parent_id:$external_parent_id
            ) {
              count
            }
          }
        `,
        variables: {
          search: search,
          module_name: props.match.params.type,
          external_parent_id: (props.match.params.external_parent_id ? parseInt(props.match.params.external_parent_id) : 0),
        },
      })
      .then((result) => setTotalDams(result.data.assets_collections_count.count));
  }

  useEffect(() => {
    countAssets();
  }, [search]);

  function handleSubmit(event) {
    event.preventDefault();

    setSearch(event.target.elements.search.value);
    setOrder(event.target.elements.order.value);
    setOrderby(event.target.elements.orderby.value);
    setPage(1);
  }

  var titles = { AnexoSala: "Arquivo", ChatBanner: "Banner da Sala Virutal" };
  if (titles[props.match.params.type]) {
    var title = titles[props.match.params.type];
  } else {
    var title = props.match.params.type;
  }



  return (

    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <div className="btn-top-container">
              <a
                href='javascript:history.go(-1)'
                className="btn btn-info btn-fill"
              >
                VOLTAR
                </a>
              <NavLink
                to={'/Dam-new/' + props.match.params.type + (props.match.params.external_parent_id ? "/" + props.match.params.external_parent_id : "")}
                className="btn btn-success btn-fill"
                activeClassName=""
              >
                Novo {title}
              </NavLink>
            </div>
            <Card
              title={(title + "s") + " ativos"}
              category="Gerenciar dams ativos"
              ctTableFullWidth
              ctTableResponsive
              content={

                <Table striped hover>
                  <thead>
                    <tr>
                      <th colSpan={9}>
                        <form onSubmit={handleSubmit}>
                          <Row>
                            <Col md={6} lg={3}>
                              <input
                                type="text"
                                name="search"
                                className="form-control"
                              />
                            </Col>
                            <Col md={2}>
                              <Button bsStyle="info" fill type="submit">
                                Buscar
                                </Button>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6} lg={1}>
                              <select name="orderby" className="form-control">
                                <option value="u.id">ID</option>
                                <option value="u.title">Título</option>
                              </select>
                            </Col>
                            <Col md={6} lg={2}>
                              <select name="order" className="form-control">
                                <option value="DESC">Decrescente</option>
                                <option value="ASC" selected={order == "ASC"}>
                                  Crescente
                                  </option>
                              </select>
                            </Col>
                            <Col md={2}>
                              <Button bsStyle="info" fill type="submit">
                                Ordenar
                                </Button>
                            </Col>
                          </Row>
                        </form>
                      </th>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Título</th>
                      <th>Categorias</th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>

                    <Query
                      client={dam_client}
                      variables={{
                        module_name: props.match.params.type,
                        external_parent_id: (props.match.params.external_parent_id ? parseInt(props.match.params.external_parent_id) : 0),
                        search,
                        limit,
                        page,
                        order,
                        orderby,
                      }}
                      query={GET_DAM}
                      fetchPolicy='no-cache'
                    >
                      {({ loading, error, data }) => {
                        if (loading) return <tr><td>Carregando...</td></tr>;
                        if (error) return <tr><td>Erro :(</td></tr>;

                        if (!data.assets_collections || data.assets_collections.assets.length === 0) {
                          return (<tr><td>(Vazio)</td></tr>);
                        }

                        return data.assets_collections.assets.map((item) => (

                          <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.title}</td>
                            <td>
                              <a className="btn btn-info" href={getUrl('dam-edit-file', props.match.params.type, item.id)}>Substituir arquivo</a>
                            </td>
                            <td>
                              {props.match.params.type === "Banner" &&
                                <a className="btn btn-info" href={Config.baseUrl + "/api/v1/analytics/report_banner?banner_id=" + item.id}>Baixar relatório</a>
                              }
                            </td>
                            <td>
                              <a className="btn btn-primary" href={getUrl('dam-edit', props.match.params.type, item.id)}>Thumb</a>
                            </td>
                            <td>
                              <a className="btn btn-info" href={getUrl('dam-edit', props.match.params.type, item.id)}>Editar</a>
                            </td>
                            <td>
                              <a className="btn btn-success" href={getUrl('segmentation', props.match.params.type, item.id)}>Segmentar</a>
                            </td>
                            <td>
                              <Button bsStyle="danger" onClick={() => {
                                confirmAlert({
                                  title: '',
                                  message: "Tem certeza que deseja excluir " + item.title,
                                  buttons: [
                                    {
                                      label: 'Sim',
                                      onClick: () => {
                                        // Delete item
                                        dam_client.mutate({
                                          mutation: DELETE_DAM,
                                          variables: {
                                            id: item.id
                                          }
                                        }).then(
                                          result => {
                                            window.location.reload();
                                          });
                                      }
                                    },
                                    {
                                      label: 'Não'
                                    }
                                  ]
                                });

                              }}>
                                Excluir
                              </Button>
                            </td>
                          </tr>
                        ));
                      }}
                    </Query>

                  </tbody>
                </Table>
              }
            />
          </Col>
          <Col sm={12}>
            <Paginator
              currentPage={page}
              pages={Math.ceil(totalDams / limit)}
              onChangePage={(page) => setPage(page)}
              buttons={6}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default DamList;
